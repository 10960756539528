import React, { useEffect } from "react"
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import NegotiationService from "../../../../services/NegotiationService"
import Chat from "../../../Chat"
import { getMyProfile } from "../../../../redux/action/accountAction"
import AccountService from "../../../../services/AccountService"
import OfferNegotiation from "../offer-negotiation.module"
import NegotiationOfferAsListModule from "../../../negotiation-offer/negotiation-offer-as-list"
import SpectatorModule from "../../../spectator/spectator.module"
import Constant from "../../../../../constant"
import NegotiationInformation from "../NegotiationInformation"
import InvitationService from "../../../../services/InvitationService"
import ChatReadOnly from "../ChatReadOnly"

const VendorNegotiationOnline = () => {
   const { negotiationId, negotiationBatchId } = useParams()
   const dispatch = useDispatch()
   const negotiationService = new NegotiationService()
   const accountService = new AccountService()
   const invitationService = new InvitationService()

   const [permitMessage, setPermitMessage] = useState(true)
   const [accountId, setAccountId] = useState(null)
   const [accountName, setAccountName] = useState(null)
   const [toAccountId, setToAccountId] = useState(null)
   const [negotiation, setNegotiation] = useState({})
   const [account, setAccount] = useState({})

   useEffect(() => {
      checkLogin()
   }, [])

   const checkInvitation = (foreignKey, _accountId) => {
      dispatch(
         invitationService.findByForeignKeyAndToAccountIdAndType(foreignKey, _accountId, "NEGOTIATION", (res) => {
            console.log(res)
         }),
      )
   }

   const checkLogin = () => {
      const email = location.href.split("?")[1].split("=")[1]
      localStorage.setItem("email", email)

      const access_token = localStorage.getItem("access_token")
      if (!access_token) {
         const currentUrl = location.href
         location.href = "/vendor-login?prev=" + currentUrl
      } else {
         dispatch(
            getMyProfile((res) => {
               const account = res.account
               setAccount(account)
               setAccountId(account._id)
               setAccountName(account.email)
               if (account.role === Constant.ROLE.SELLER_AUCTION) {
                  getNegotiationDetail()
               } else if (account.role === Constant.ROLE.SPECTATOR_AUCTION) {
                  checkInvitation(negotiationId, account._id)
               }
            }),
         )
      }
   }

   const getNegotiationDetail = () => {
      dispatch(
         negotiationService.findById(negotiationId, (res) => {
            setNegotiation(res)
            setToAccountId(res.fromAccountId)
            dispatch(
               accountService.findById(res.toAccountId, (resAccountTo) => {
                  setAccountName(resAccountTo.email)
                  setAccountId(resAccountTo._id)
               }),
            )
         }),
      )
   }

   return (
      <div className="ml-5 mr-4" style={{ marginTop: "10px" }}>
         <Row className="mx-0 px-0">
            <Col className="px-0" md="8">
               <NegotiationInformation
                  account={account}
                  negotiationBatchId={negotiationBatchId}
                  negotiationId={negotiationId}
               ></NegotiationInformation>
            </Col>
            <Col md="4">
               {accountId !== null && (
                  <SpectatorModule roomId={negotiationId} userId={accountId} permitAdd={false}></SpectatorModule>
               )}
            </Col>
         </Row>
         <Row className="mt-4">
            <Col md="5" className="px-0">
               {accountName !== null &&
               accountId !== null &&
               (negotiation?.status === Constant.STATUS.ON_GOING || negotiation?.status === Constant.STATUS.DONE) ? (
                  <Chat
                     permitSendMessage={permitMessage}
                     roomId={negotiationId}
                     username={accountName}
                     userId={accountId}
                     account={account}
                     negotiation={negotiation}
                  ></Chat>
               ) : (
                  <ChatReadOnly negotiation={negotiation} />
               )}
            </Col>
            <Col md="7">
               {accountName !== null && accountId !== null && (
                  <OfferNegotiation
                     account={account}
                     username={accountName}
                     negotiationId={negotiationId}
                     accountId={accountId}
                     fromAccountId={accountId}
                     toAccountId={toAccountId}
                     negotiation={negotiation}
                     setNegotiation={setNegotiation}
                  ></OfferNegotiation>
               )}
            </Col>
         </Row>
         <Row className="mt-4 mx-0 mb-5">
            <Col md="12" className="px-0">
               {accountName !== null && accountId !== null && (
                  <NegotiationOfferAsListModule
                     roomId={negotiationId}
                     negotiationId={negotiationId}
                     username={accountName}
                  ></NegotiationOfferAsListModule>
               )}
            </Col>
         </Row>
      </div>
   )
}

export default VendorNegotiationOnline
