import React from "react"
import ReactFlagsSelect from "react-flags-select"

const Language = () => {
   const setLang = (lang) => {
      if (!lang) {
         localStorage.setItem("lang", "US")
      } else {
         localStorage.setItem("lang", lang)
      }
      location.reload()
   }

   const getDefaultLang = () => {
      return localStorage.getItem("lang")
   }

   return (
      <>
         <ReactFlagsSelect
            selected={getDefaultLang()}
            countries={["US", "ID"]}
            customLabels={{ US: "English", ID: "Indonesia" }}
            onSelect={(v) => {
               setLang(v)
            }}
         ></ReactFlagsSelect>
      </>
   )
}

export default Language
