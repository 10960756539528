import React from 'react'
import styled from 'styled-components'

export default function Stack({children, style, vcentered}) {
    return (
        <StackStyle style={style} vcentered={vcentered}>
            {children}
        </StackStyle>
    )
}

const StackStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.vcentered ? 'center' : 'flex-start'};
`