import React, { useEffect, useState, useLayoutEffect } from "react"
import { Col, InputGroup, Nav, Navbar, Row } from "react-bootstrap"
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Dropdown, Button } from "rsuite"
import styled from "styled-components"
import LogoProcurite from "../assets/images/logo-01.png"
import AuctionBanner from "../assets/images/AuctionBanner.png"
import FlagEN from "../assets/images/flag-en.svg"
import FlagID from "../assets/images/flag-id.svg"
import ChevronDown from "../assets/images/landingpage/icon/chevron-down.svg"
import LogoutIcon from "../assets/images/topmenuicons/GreyLogoutIcon.svg"
import SettingIcon from "../assets/images/Setting.svg"
import KeyGreyIcon from "../assets/images/KeyGrey.svg"
import Dateinfo from "../components/Dateinfo"
import { logout } from "../redux/action/authAction"
// import { fetchLatestNotification } from '../redux/actions/accountAction';
// import Keycloakservice from '../services/Keycloakservice';
import Buttonaction from "./Buttonaction"
import { HeadermenuItemAccount } from "./HeadermenuItem"
import ImgAvatar from "../assets/images/img7.png"
import QuestionCircleIcon from "../assets/images/QuestionCircleIcon.svg"
import BellIcon from "../assets/images/GreyBellIconBig.svg"
import { getMyProfile } from "../redux/action/accountAction"
import Constant from "../../constant"
import { v1 } from "uuid"
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine"

export function HeadermenuOnboarding() {
   return (
      <>
         <NavContainer className="navbar navbar-expand-lg" fullwidth>
            <NavbarCollapse>
               <NavbarLeft className="py-3 px-3 col-md-4">
                  <LogoImage src={LogoProcurite} />
               </NavbarLeft>
               <NavbarRight className="col-md-4">
                  {/* <HeadermenuItemAccount picture="EC" name={Keycloakservice.getUsername()} /> */}
                  {/* <HeadermenuItemAccount picture="EC" name="auction tes account" /> */}
               </NavbarRight>
            </NavbarCollapse>
         </NavContainer>
      </>
   )
}

export function Headermenu({
   dataProfile,
   dataNotifications,
   unreadNotifications,
   dataCartId,
   expand,
   props,
   isVendor,
   showMenus,
   fullwidth,
}) {
   const history = useHistory()
   const dispatch = useDispatch()
   const [notificationSkip, setNotificationSkip] = useState(0)
   // const { dataCompany } = useSelector(state => state.companyReducer);
   const [firstName, setFirstName] = useState("")
   const [lastName, setLastName] = useState("")
   const [role, setRole] = useState("")
   const [email, setEmail] = useState("")
   const [date, setDate] = useState(new Date())

   const getCurrentLang = () => {
      return localStorage.getItem("lang")
   }

   const setLang = (lang) => {
      localStorage.setItem("lang", lang)
      location.reload()
   }

   // useLayoutEffect(() => {
   //   if (Object.entries(dataProfile).length > 0) {
   //     const { firstName, lastName } = dataProfile.account;
   //     setFirstName(firstName);
   //     setLastName(lastName);
   //   }
   // });

   useEffect(() => {
      dispatch(
         getMyProfile(
            (_data) => {
               if (_data.account) {
                  setFirstName(_data.account.firstName)
                  setLastName(_data.account.lastName)
                  setRole(_data.account.role)
                  setEmail(_data.account.email)
               }
            },
            (_err) => {},
         ),
      )
   }, [])

   // useEffect(() => {
   //   const timer = setInterval(() => {
   //     // Creates an interval which will update the current data every minute
   //     // This will trigger a rerender every component that uses the useDate hook.
   //     setDate(new Date());
   //   }, 60 * 1000);
   //   return () => {
   //     clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
   //   };
   // }, []);

   React.useEffect(() => {
      const timerID = setInterval(() => tick(), 1000)

      return function cleanup() {
         clearInterval(timerID)
      }
   })

   function tick() {
      setDate(new Date())
   }

   const renderButton = (props, ref) => {
      const { image, text } = props
      return (
         <Button
            {...props}
            ref={ref}
            style={{ background: "transparent", fontWeight: "500", fontSize: ".7rem" }}
            className="d-flex justify-content-center align-items-center"
         >
            <Avatar src={image} />
            {text}
            <ArrowDownLineIcon style={{ fontSize: "1.2em", marginLeft: "10px" }} />
         </Button>
      )
   }

   return (
      <>
         <NavContainer
            {...props}
            expand={expand}
            fullwidth={fullwidth}
            className={`navbar navbar-expand-lg fixed-top ${expand ? "expand" : ""}`}
         >
            <NavbarCollapse>
               <NavbarLeft className="py-3 col-md-4">
                  {isVendor && <LogoImage src={AuctionBanner} style={{ marginRight: "60px" }} />}
                  <Dateinfo
                     date={new Date()}
                     format="dddd, dd mmmm yyyy"
                     style={{ color: "#454545", fontWeight: "500" }}
                  />
                  <p
                     style={{
                        color: "#454545",
                        fontWeight: "500",
                        marginLeft: "12px",
                        fontSize: "13px",
                     }}
                  >
                     {date.getHours().toString().length < 2 ? "0" + date.getHours() : date.getHours()}:
                     {date.getMinutes().toString().length < 2 ? "0" + date.getMinutes() : date.getMinutes()}:
                     {date.getSeconds().toString().length < 2 ? "0" + date.getSeconds() : date.getSeconds()}
                  </p>
               </NavbarLeft>

               <NavbarRight className="col-md-8">
                  {!showMenus ? (
                     <>
                        {/* <img src={BellIcon} />
                        <Border /> */}
                        <Dropdown
                           placement="bottomEnd"
                           renderToggle={(props, ref) => {
                              const image = getCurrentLang() == "US" ? FlagEN : FlagID
                              const text = getCurrentLang() == "US" ? "EN" : "ID"
                              return renderButton({ ...props, image, text }, ref)
                           }}
                        >
                           <Dropdown.Item onClick={(v) => setLang("US")} style={{ width: "200px" }}>
                              <img src={FlagEN} />
                              <ProfileItem>English</ProfileItem>
                           </Dropdown.Item>
                           <Dropdown.Item onClick={(v) => setLang("ID")}>
                              <img src={FlagID} />
                              <ProfileItem>Indonesia</ProfileItem>
                           </Dropdown.Item>
                        </Dropdown>
                        <Border />
                        <div className="d-flex flex-row align-items-center">
                           <img src={QuestionCircleIcon} />
                           <Help
                              onClick={() => {
                                 history.push("/help/buyer")
                              }}
                           >
                              Help
                           </Help>
                        </div>
                        <Border />
                        <Dropdown
                           placement="bottomEnd"
                           renderToggle={(props, ref) => {
                              const image = ImgAvatar
                              const text = firstName + (lastName && " " + lastName)
                              return renderButton({ ...props, image, text }, ref)
                           }}
                        >
                           {/* <Dropdown.Item>
                                 <img src={SettingIcon} />
                                 <ProfileItem>Account Setting</ProfileItem>
                              </Dropdown.Item> */}
                           {dataProfile?.account?.role !== Constant.ROLE.SUPER_ADMIN &&
                           dataProfile?.account?.role !== Constant.ROLE.ADMIN ? (
                              <Dropdown.Item style={{ width: "200px" }}>
                                 <img src={KeyGreyIcon} />
                                 <ProfileItem onClick={() => history.push("/account/change-password")}>
                                    Change Password
                                 </ProfileItem>
                              </Dropdown.Item>
                           ) : (
                              <></>
                           )}
                           <Dropdown.Item onClick={() => dispatch(logout(email))}>
                              <img src={LogoutIcon} />
                              {/* <ProfileItem onClick={() => Keycloakservice.doLogout()}>
                        Log Out
                      </ProfileItem> */}
                              <ProfileItem>Log Out</ProfileItem>
                           </Dropdown.Item>
                        </Dropdown>
                     </>
                  ) : showMenus && role !== Constant.ROLE.SPECTATOR_AUCTION ? (
                     <>
                        <div className="d-flex flex-row align-items-center">
                           <img src={QuestionCircleIcon} />
                           <Help
                              onClick={() => {
                                 history.push("/help/vendor")
                              }}
                           >
                              Help
                           </Help>
                        </div>
                        <Border />

                        <Dropdown
                           menuStyle={{ minWidth: "200px" }}
                           title={<HeadermenuItemAccount name={firstName + (lastName && " " + lastName)} />}
                        >
                           {/* <Dropdown.Item>
              <img src={SettingIcon} />
              <ProfileItem>Setting</ProfileItem>
            </Dropdown.Item>
            <Dropdown.Item>
              <img src={LockIcon} />
              <ProfileItem
                onClick={() => history.push("/changepassword/method")}
              >
                Change Password
              </ProfileItem>
            </Dropdown.Item> */}
                           <Dropdown.Item onClick={() => dispatch(logout(email))}>
                              <img src={LogoutIcon} />
                              {/* <ProfileItem onClick={() => Keycloakservice.doLogout()}>
                Log Out
              </ProfileItem> */}
                              <ProfileItem>Log Out</ProfileItem>
                           </Dropdown.Item>
                        </Dropdown>
                     </>
                  ) : (
                     showMenus &&
                     role === Constant.ROLE.SPECTATOR_AUCTION && (
                        <Dropdown menuStyle={{ minWidth: "200px" }} title={<HeadermenuItemAccount name={email} />}>
                           <Dropdown.Item onClick={() => dispatch(logout(email))}>
                              <img src={LogoutIcon} />

                              <ProfileItem>Log Out</ProfileItem>
                           </Dropdown.Item>
                        </Dropdown>
                     )
                  )}
               </NavbarRight>
            </NavbarCollapse>
         </NavContainer>
      </>
   )
   function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
   }
}

const mapStateToProps = (state) => {
   return {
      dataProfile: state.accountReducer.dataProfile,
      // dataCartId: state.cartReducer.dataCartId,
      // dataNotifications: state.accountReducer.dataNotifications,
      // unreadNotifications: state.accountReducer.unreadNotifications
   }
}

export default connect(mapStateToProps)(Headermenu)

const NavContainer = styled(Nav).attrs((props) => {
   className: props.className
})`
   left: ${(props) => (props.fullwidth ? 0 : "235px")};
   padding-left: ${(props) => (props.fullwidth ? "1rem" : "16px")};
   background-color: white;
   transition: ease-in-out 0.3s all;

   &.expand {
      left: 84px;
   }

   .navbar-collapse {
      justify-content: space-between;
   }
`

const NavbarLeft = styled(Navbar).attrs((props) => ({
   className: props.className,
}))`
   justify-content: flex-start;
   padding-left: 0;
`

const NavbarRight = styled(Navbar).attrs((props) => ({
   className: props.className,
}))`
   justify-content: flex-end;
`

const FullWidthInputGroup = styled(InputGroup)`
   width: 100% !important;
`

const LogoImage = styled.img`
   width: 100px;
`
const LabelCompare = styled.span`
   text-align: center;
   z-index: 1;
   margin-left: -20px;
   margin-top: -10px;
   border-radius: 50%;
   background-color: red;
   color: white;
   font-size: 10px;
   min-height: 15px;
   min-width: 15px;
`

const ProfileItem = styled.span`
   margin-left: 12px;
   font-size: 0.7rem;
`
const Breaker = styled.div`
   margin: 12px 0;
   background-color: #f3f5f4;
   height: 4px;
   margin: 0px auto 18px;
   width: 90%;
`

const Avatar = styled.img`
   border-radius: 50px;
   height: 32px;
   margin-right: 8px;
   width: 32px;
`

// const Name = styled.a`
//   color: #454545;
//   overflow: hidden;
//   text-decoration: none;
//   text-overflow: ellipsis;
//   white-space: nowrap;

//   &:hover {
//     color: #454545;
//     text-decoration: none;
//   }
// `;

const Help = styled.span`
   color: #909090;
   margin-left: 0.5rem;
   text-decoration: none !important;
   font-size: 0.7rem;
   cursor: pointer;

   &:hover {
      color: #909090;
   }
`

const Border = styled.div`
   width: 1px;
   height: 40px;
   margin-left: 1.2rem;
   margin-right: 1.2rem;
   border-right: 1.5px solid #c4c4c4;
`
