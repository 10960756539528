import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { GreyCard } from "../../../components/styled/Generalcard"
import sandClockLarge from "../../../assets/images/sand-clock-lg.svg"
import browserTab from "../../../assets/images/browser-tab.svg"
import Constant from "../../../../constant"
import { getMyProfile } from "../../../redux/action/accountAction"
import Dateinfo from "../../../components/Dateinfo"

const ChatReadOnly = ({ negotiation }) => {
   const dispatch = useDispatch()
   const [picName, setPicName] = useState(null)

   useEffect(() => {
      dispatch(getMyProfile((res) => setPicName(res.account)))
   }, [])

   return (
      <>
         <GreyCard className="px-0 h-100">
            <div
               className="text-center ml-3 mr-3"
               style={{
                  height: "100%",
                  position: "relative",
                  backgroundColor: "#F3F5F4",
                  borderRadius: "5px",
               }}
            >
               <div
                  style={{
                     position: "absolute",
                     top: "50%",
                     left: "50%",
                     marginLeft:
                        negotiation?.status === Constant.STATUS.CANCEL
                           ? "-165px"
                           : negotiation?.status === Constant.STATUS.NOT_STARTED && "-50px",
                     marginTop: "-75px",
                  }}
               >
                  <img
                     src={
                        negotiation?.status === Constant.STATUS.CANCEL
                           ? browserTab
                           : negotiation?.status === Constant.STATUS.NOT_STARTED && sandClockLarge
                     }
                  />
                  {negotiation?.status === Constant.STATUS.CANCEL ? (
                     <div className="mt-3" style={{ color: "#909090", fontSize: "12px", fontWeight: "400" }}>
                        <p>
                           This negotiation has been canceled in{" "}
                           <strong>
                              <Dateinfo
                                 date={negotiation?.startDate}
                                 format="dd mmmm yyyy hh:mm:ss"
                                 style={{ all: "unset" }}
                              />
                           </strong>
                        </p>
                        <p>
                           by{" "}
                           <strong>
                              {" "}
                              {picName?.firstName} {picName?.lastName}{" "}
                           </strong>
                        </p>
                        <p>
                           The reason is{" "}
                           <strong
                              style={{ display: "inline-block" }}
                              dangerouslySetInnerHTML={{
                                 __html: negotiation?.cancelReason,
                              }}
                           />
                        </p>
                     </div>
                  ) : (
                     negotiation?.status === Constant.STATUS.NOT_STARTED && (
                        <div
                           style={{
                              marginTop: "28px",
                              backgroundColor: "#909090",
                              borderRadius: "50px",
                              color: "#FFFFFF",
                              fontSize: "10px",
                              fontWeight: "500",
                              padding: "3px 17px 3px 17px",
                           }}
                        >
                           Not Started
                        </div>
                     )
                  )}
               </div>
            </div>
         </GreyCard>
      </>
   )
}

export default ChatReadOnly
