import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { io } from "socket.io-client"
import Constant from "../../constant"
import styles from "../styles/Chat.module.css"
import { getChatMessages, searchMessageById } from "../services/ChatService"
import { orderBy as _orderBy } from "lodash"
import toast from "react-hot-toast"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import { GreyCard } from "../components/styled/Generalcard"
import styled from "styled-components"
import Dateinfo from "../components/Dateinfo"
import WarningIcon from "../assets/images/WarningIconLarge.svg"
import CheckCircleIcon from "../assets/images/CheckCircleGreen.svg"
import CircleXRedSmallIcon from "../assets/images/CircleXRedSmall.svg"
import CheckGearYellowIcon from "../assets/images/CheckGearYellow.svg"

export default function Chat(param) {
   const roomId = param.roomId
   const username = param.username
   const userId = param.userId
   const permitSendMessage = param.permitSendMessage
   const account = param.account
   const negotiation = param.negotiation

   const dispatch = useDispatch()
   const messageEndRef = useRef(null)
   const [message, setMessage] = useState("")
   const [displayMessage, setDisplayMessage] = useState([])
   const [isJoin, setIsJoin] = useState(false)

   /**
    * Socket setup
    * ----------------
    */
   const ENDPOINT = import.meta.env.VITE_SERVER_URL
   const socketRef = useRef()

   const setupSocket = () => {
      socketRef.current = io(ENDPOINT + `/room-${roomId}`)

      socketRef.current.on(Constant.socketEvents.RESPONSE_MESSAGE, (resp) => {
         const id = resp.body._id
         dispatch(
            searchMessageById(id, (res) => {
               const newMessage = {
                  senderId: res.body._source.senderId,
                  company: res.body._source.senderCompany ? res.body._source.senderCompany : "N/A",
                  name: res.body._source.senderName,
                  message: res.body._source.message,
                  chatType: res.body._source.chatType,
                  dealConfirmed: res.body._source.dealConfirmed,
                  dealNotConfirmed: res.body._source.dealNotConfirmed,
                  waitingDealConfirmation: res.body._source.waitingDealConfirmation,
                  createdAt: res.body._source.createdAt,
                  fromRole: res.body._source.fromRole,
                  priceDeal: res.body._source.priceDeal,
               }
               setDisplayMessage((prevMessages) => {
                  const newMessages = [...prevMessages, newMessage]
                  return newMessages
               })
               scrollToBottom()
            }),
         )
      })

      socketRef.current.emit(Constant.socketEvents.JOIN_CHAT_ROOM, {
         roomId: Constant.ROOM_SOCKET.CHAT + "-" + roomId,
         username: localStorage.getItem("chatUsername"),
      })

      socketRef.current.on(Constant.socketEvents.RESPONSE_JOIN_CHAT_ROOM, (resp) => {
         toast.success(resp.message)
      })

      return () => {
         socketRef.current.disconnect
      }
   }

   useEffect(() => {
      handleIsJoin()
      setUpLibraryFA()
   }, [])

   const setUpLibraryFA = () => {
      library.add(fas, faPaperPlane)
   }

   const addAndSortMessages = (newMessages) => {
      if (newMessages.length > 0) {
         const copyMessages = [...displayMessage]
         const stateVal = []
         for (const item of newMessages) {
            stateVal.push({
               senderId: item._source.senderId,
               company: item._source.senderCompany ? item._source.senderCompany : "N/A",
               name: item._source.senderName,
               message: item._source.message,
               chatType: item._source.chatType,
               dealConfirmed: item._source.dealConfirmed,
               dealNotConfirmed: item._source.dealNotConfirmed,
               waitingDealConfirmation: item._source.waitingDealConfirmation,
               createdAt: item._source.createdAt,
               fromRole: item._source.fromRole,
            })
         }
         const concatArray = copyMessages.concat(stateVal)
         setDisplayMessage(_orderBy(concatArray, ["createdAt"], ["asc"]))
      }
   }

   const handleMessages = () => {
      return getChatMessages(roomId, (res) => {
         if (res) {
            const result = res.body.hits.hits
            addAndSortMessages(result)
            scrollToBottom()
         }
      })
   }

   const scrollToBottom = () => {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" })
   }

   const handleIsJoin = () => {
      if (username) {
         joinRoom()
         setIsJoin(true)
         setupSocket(displayMessage)
         dispatch(handleMessages())
      }
   }

   const sendMessage = () => {
      socketRef.current.emit(Constant.socketEvents.NEW_MESSAGE, {
         roomId: roomId,
         senderId: userId,
         role: account.role,
         senderName: localStorage.getItem("chatUsername"),
         message: message,
         createdAt: new Date(Date.now()),
      })
      setMessage("")
   }

   const joinRoom = () => {
      localStorage.setItem("chatUsername", username)
      setIsJoin(true)
   }

   const handleEnter = (target) => {
      if (target.charCode == 13) {
         sendMessage()
      }
   }

   const className = (message) => {
      if (message.name.toLowerCase() === localStorage.getItem("chatUsername").toLowerCase()) {
         return styles.messagesMessageCurrentMember
      }
      return styles.messagesMessage
   }

   const RenderBubbleChat = ({ message }) => {
      if (message.name.toLowerCase() === localStorage.getItem("chatUsername").toLowerCase()) {
         return (
            <>
               <BubbleContainer className="align-self-end">
                  {message.waitingDealConfirmation && (
                     <OfferBubbleAlert senderId={message.senderId} userId={userId}>
                        <img src={CheckCircleIcon} style={{ height: "16px" }} />
                        {message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                           <span className={styles.bubbleAlertMessage}>
                              You have dealt with buyer’s offer. Waiting for buyer’s confirmation.
                           </span>
                        ) : (
                           <span className={styles.bubbleAlertMessage}>
                              You have dealt with vendor’s offer. Waiting for vendor’s confirmation.
                           </span>
                        )}
                     </OfferBubbleAlert>
                  )}
                  {message.dealNotConfirmed && (
                     <OfferBubble style={{ background: "#FDE9EF" }}>
                        <Row style={{ fontWeight: 400 }}>
                           <Col lg={8} style={{ marginBottom: "8px" }}>
                              <VendorName title={message.company} style={{ color: "#454545" }}>
                                 {message.company} (You)
                              </VendorName>
                           </Col>
                           <Col lg={4} className="d-flex justify-content-end">
                              <span
                                 style={{
                                    color: "#454545",
                                    fontSize: "12px",
                                 }}
                              >
                                 {new Date(message.createdAt).toLocaleString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                 })}
                              </span>
                           </Col>
                        </Row>
                        <Row>
                           <Col
                              style={{
                                 color: "#454545",
                                 fontWeight: 500,
                                 paddingLeft: "15px",
                                 paddingRight: "15px",
                                 display: "flex",
                              }}
                           >
                              <img src={CircleXRedSmallIcon} style={{ height: "20px", marginRight: "10px" }} />
                              {message.senderId === userId ? (
                                 <span>You have canceled the deal</span>
                              ) : message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                                 <p>Vendor Canceled Deal</p>
                              ) : (
                                 <p>Buyer Canceled Deal</p>
                              )}
                           </Col>
                        </Row>
                     </OfferBubble>
                  )}

                  {message.dealConfirmed && (
                     <OfferBubble style={{ background: "#EDFAE1" }}>
                        <Row>
                           <Col lg={8} style={{ marginBottom: "8px" }}>
                              <VendorName title={message.company} style={{ color: "#454545" }}>
                                 {message.company} (You)
                              </VendorName>
                           </Col>
                           <Col lg={4} className="d-flex justify-content-end">
                              <span
                                 style={{
                                    color: message.chatType === "OFFER" ? "#454545" : "#ffffff",
                                    fontSize: "12px",
                                 }}
                              >
                                 {new Date(message.createdAt).toLocaleString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                 })}
                              </span>
                           </Col>
                        </Row>
                        <Row>
                           <Col
                              style={{
                                 color: "#454545",
                                 fontWeight: 500,
                                 paddingLeft: "15px",
                                 paddingRight: "15px",
                                 display: "flex",
                              }}
                           >
                              <img src={CheckGearYellowIcon} style={{ height: "20px", marginRight: "10px" }} />
                              {message.senderId === userId ? (
                                 <span>You have confirmed to deal</span>
                              ) : message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                                 <p>Vendor has confirmed to Deal</p>
                              ) : (
                                 <p>Buyer has confirmed Deal</p>
                              )}
                           </Col>
                        </Row>
                     </OfferBubble>
                  )}

                  {!message.dealConfirmed && !message.dealNotConfirmed && (
                     <OfferBubble
                        waitingDealConfirmation={message.waitingDealConfirmation}
                        style={{ backgroundColor: message.chatType === "OFFER" ? "#E9F5FE" : "#0772b6" }}
                     >
                        <Row style={{ fontWeight: 400 }}>
                           <Col lg={8} style={{ marginBottom: "8px" }}>
                              <VendorName
                                 title={message.company}
                                 style={{ color: message.chatType === "OFFER" && "#454545" }}
                              >
                                 {message.company} (You)
                              </VendorName>
                           </Col>
                           <Col lg={4} className="d-flex justify-content-end">
                              <span
                                 style={{
                                    color: message.chatType === "OFFER" ? "#454545" : "#ffffff",
                                    fontSize: "12px",
                                 }}
                              >
                                 {new Date(message.createdAt).toLocaleString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                 })}
                              </span>
                           </Col>
                        </Row>
                        <Row>
                           <Col
                              style={{
                                 color: message.chatType === "OFFER" ? "#454545" : "white",
                                 fontWeight: 500,
                                 paddingLeft: "15px",
                                 paddingRight: "15px",
                              }}
                           >
                              <p>{message.message}</p>
                           </Col>
                        </Row>
                     </OfferBubble>
                  )}
               </BubbleContainer>
            </>
         )
      }
      return (
         <>
            <BubbleContainer>
               {message.waitingDealConfirmation && (
                  <OfferBubbleAlert senderId={message.senderId} userId={userId}>
                     <img src={WarningIcon} style={{ height: "16px" }} />
                     {message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                        <span className={styles.bubbleAlertMessage}>
                           Vendor has dealt with your offer. Waiting for your confirmation.
                        </span>
                     ) : (
                        <span className={styles.bubbleAlertMessage}>
                           Buyer has dealt with your offer. Waiting for your confirmation.
                        </span>
                     )}
                  </OfferBubbleAlert>
               )}

               {message.dealNotConfirmed && (
                  <OfferBubble style={{ background: "#FDE9EF" }}>
                     <Row style={{ fontWeight: 400 }}>
                        <Col lg={8} style={{ marginBottom: "8px" }}>
                           <VendorName title={message.company} style={{ color: "#454545" }}>
                              {message.company} ({message.name})
                           </VendorName>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-end">
                           <span
                              style={{
                                 color: "#454545",
                                 fontSize: "12px",
                              }}
                           >
                              {new Date(message.createdAt).toLocaleString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 hour12: false,
                              })}
                           </span>
                        </Col>
                     </Row>
                     <Row>
                        <Col
                           style={{
                              color: "#454545",
                              fontWeight: 500,
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              display: "flex",
                           }}
                        >
                           <img src={CircleXRedSmallIcon} style={{ height: "20px", marginRight: "10px" }} />
                           {message.senderId === userId ? (
                              <span>You have canceled the deal</span>
                           ) : message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                              <p>Vendor Canceled Deal</p>
                           ) : (
                              <p>Buyer Canceled Deal</p>
                           )}
                        </Col>
                     </Row>
                  </OfferBubble>
               )}

               {message.dealConfirmed && (
                  <OfferBubble style={{ background: "#EDFAE1" }}>
                     <Row>
                        <Col lg={8} style={{ marginBottom: "8px" }}>
                           <VendorName title={message.company} style={{ color: "black" }}>
                              {message.company} ({message.name})
                           </VendorName>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-end">
                           <span
                              style={{
                                 color: "#454545",
                                 fontSize: "12px",
                              }}
                           >
                              {new Date(message.createdAt).toLocaleString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 hour12: false,
                              })}
                           </span>
                        </Col>
                     </Row>
                     <Row>
                        <Col
                           style={{
                              color: "#454545",
                              fontWeight: 500,
                              paddingLeft: "15px",
                              paddingRight: "15px",
                              display: "flex",
                           }}
                        >
                           <img src={CheckGearYellowIcon} style={{ height: "20px", marginRight: "10px" }} />
                           {message.senderId === userId ? (
                              <span>You have confirmed to deal</span>
                           ) : message.fromRole === Constant.ROLE.SELLER_AUCTION ? (
                              <p>Vendor has confirmed to Deal</p>
                           ) : (
                              <p>Buyer has confirmed Deal</p>
                           )}
                        </Col>
                     </Row>
                  </OfferBubble>
               )}

               {!message.dealConfirmed && !message.dealNotConfirmed && (
                  <OfferBubble
                     waitingDealConfirmation={message.waitingDealConfirmation}
                     style={{ backgroundColor: message.chatType === "OFFER" ? "#E9F5FE" : "#ffffff" }}
                  >
                     <Row>
                        <Col lg={8} style={{ marginBottom: "8px" }}>
                           <VendorName title={message.company} style={{ color: "black" }}>
                              {/* {message.company} ({`${account?.firstName} ${account?.lastName}`}) */}
                              {message.company} ({message.name})
                           </VendorName>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-end">
                           <span
                              style={{
                                 color: "#454545",
                                 fontSize: "12px",
                              }}
                           >
                              {new Date(message.createdAt).toLocaleString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 hour12: false,
                              })}
                           </span>
                        </Col>
                     </Row>
                     <Row>
                        <Col
                           style={{
                              color: message.chatType === "OFFER" ? "#454545" : "#454545",
                              fontWeight: 500,
                              paddingLeft: "15px",
                              paddingRight: "15px",
                           }}
                        >
                           {message.message}
                        </Col>
                     </Row>
                  </OfferBubble>
               )}
            </BubbleContainer>
         </>
      )
   }

   return (
      <>
         <GreyCard className="h-100">
            {isJoin === true && (
               <div className={styles.messagesList}>
                  {displayMessage?.map((r) => (
                     <RenderBubbleChat message={r} />
                  ))}
                  {negotiation?.status === Constant.STATUS.DONE && (
                     <div
                        className="mx-1 mb-2"
                        style={{
                           backgroundColor: "#ECECEC",
                           borderRadius: "5px",
                           padding: "10px",
                        }}
                     >
                        <div
                           className="d-flex justify-content-center mr-2"
                           style={{ color: "#909090", fontSize: "12px", fontWeight: "500" }}
                        >
                           This negotiation has been closed in&nbsp;
                           <Dateinfo
                              style={{ all: "unset" }}
                              date={negotiation?.endDate}
                              format="dd mmmm yyyy HH:MM:ss"
                           />
                        </div>
                     </div>
                  )}
               </div>
            )}
            {isJoin === true && permitSendMessage === true && (
               <div className={styles.inputMessageContainer}>
                  <Form.Group className="mb-0">
                     <Form.Row>
                        <Col md="12">
                           <Form.Control
                              disabled={negotiation?.status === Constant.STATUS.DONE}
                              style={{ height: "40px" }}
                              as="input"
                              onKeyPress={handleEnter}
                              // className={styles.inputMessage}
                              placeholder="Type something..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                           ></Form.Control>
                        </Col>
                        {/* <Col md="2">
                    <Button block="true" onClick={sendMessage} variant="primary">
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                      />
                    </Button>
                  </Col> */}
                     </Form.Row>
                  </Form.Group>
               </div>
            )}
         </GreyCard>
      </>
   )
}

const BubbleContainer = styled.div`
   min-width: 300px;
   max-width: 80%;
   margin-bottom: 26px;
`

const OfferBubble = styled.div`
   border: 1px solid #c4c4c4;
   padding: 16px;
   border-radius: ${({ waitingDealConfirmation }) => (waitingDealConfirmation ? "0 0 5px 5px" : "5px")};
`

const OfferBubbleAlert = styled.div`
   color: #454545;
   font-weight: 500;
   border-top: 1px solid #c4c4c4;
   border-left: 1px solid #c4c4c4;
   border-right: 1px solid #c4c4c4;
   background: ${({ senderId, userId }) => (senderId === userId ? "#EDFAE1" : "#fff9e6")};
   width: 100%;
   padding: 8px 16px;
   border-radius: 5px 5px 0 0;
   display: flex;
   align-items: center;
`

const VendorName = styled.p`
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: 12px;
   cursor: default;
   color: white;
`
