import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router"
import { useHistory } from "react-router"
import { Col, Row } from "react-bootstrap"
import { orderBy as _orderBy } from "lodash"

// Assets
import Trophy from "../../assets/images/Icon-Trophy.svg"
import PaperclipIcon from "../../assets/images/Paperclip.svg"
import iconResendInvitation from "../../assets/images/Icon-ResendInvitation.svg"

// Components
import Chip from "../../components/Chip"
import ModalForm from "../../components/Modalform"
import Statuslabel from "../../components/Statuslabel"
import MuiDataTable from "../../components/MuiDataTable"
import { resendJoinAuctionEmail } from "../../redux/action/procurementAuctionAction"

// Redux
import { connect, useDispatch } from "react-redux"
import {
   fetchProcurementVendors,
   fetchAuctionInvitedVendors,
   getVendorsByProcurementItemId,
   getVendorsByProcurementItemIdAndAuctionId,
   getProcurementVendorByIds,
} from "../../redux/action/procurementAction"
import { getVendorItems } from "../../redux/action/auctionAction"

// Mui library
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

// #
import Constant from "../../../constant"
import Formservice from "../../services/Formservice"
import ProcurementVendorUtil from "../../services/utils/ProcurementVendorUtil"
import VendorsPerItem from "../initiation/scoring/modals/VendorsPerItem"
import ProcurementVendorItemUtil from "../../services/utils/ProcurementVendorItemUtil"
import { Loader } from "rsuite"

function BuyerAuctionVendorListUnit({
   isInitial,
   ranks,
   dataProcurement,
   // dataProcurementVendors,
   dataAuctionInvitations,
   dataProcurementVendors,
   setDataProcurementVendors,
   auctionId,
   winnerVendor,
   auctionStatus,
   vendorsPerItem,
   setVendorsPerItem,
   auctionItems,
   procurementVendorIds,
   procurementItemId,
   setIsInitial,
}) {
   const vendorAuctionHistoryRef = useRef()
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const history = useHistory()

   // Component state
   // const [vendorsPerItem, setVendorsPerItem] = useState({ isLoading: false, isError: false, data: [] })
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [finalOffer, setFinalOffer] = useState(null)
   const [dataInvitedVendors, setDataInvitedVendors] = useState({})
   const [emptySearchResult, setEmptySearchResult] = useState(false)
   const [dataAuctionPerVendor, setDataAuctionPerVendor] = useState({})
   const [vendorUnitPriceHistory, setVendorUnitPriceHistory] = useState([])
   // const [dataProcurementVendors, setDataProcurementVendors] = useState({});
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
   })

   /**
    * Table handling
    * ----------------
    */
   const historyColumn = ["Item Code", "Item Name", "Qty", "UOM", "Unit Price", "Subtotal"]
   const handleRequestSortHistory = (column) => {
      console.log({ column })
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "item.itemCode"
            : column === "Item Name"
            ? "item.itemName"
            : column === "Qty"
            ? "item.quantity"
            : column === "UOM"
            ? "item.uom"
            : column === "Unit Price"
            ? "lastPrice"
            : column === "Subtotal"
            ? "item.subTotal"
            : ""

      let result = []
      result = _orderBy(vendorUnitPriceHistory, [forColumn], [_order])

      setVendorUnitPriceHistory(result)
   }

   const [isLoading, setIsLoading] = useState(false)
   const search = () => {
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            getVendorsByProcurementItemIdAndAuctionId(
               procurementItemId,
               auctionId,
               (procurementVendorItems) => {
                  resolve(procurementVendorItems)
               },
               () => reject(),
            ),
         )
      })
         .then((procurementVendorItems) => {
            const priceExist = procurementVendorItems.filter((vendor) => vendor.lastPrice > 0).length > 0
            if (!priceExist) {
               setIsInitial(true)
               const filtered = procurementVendorItems.filter((vendor) =>
                  vendor.vendorName.toLowerCase().includes(dataState.query),
               )
               setVendorsPerItem({ ...vendorsPerItem, data: filtered })
               setIsLoading(false)
            } else {
               dispatch(
                  getProcurementVendorByIds(
                     procurementVendorIds,
                     (procurementVendors) => {
                        let winnerList = []
                        if (dataProcurement.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT) {
                           const sortByWinner = ProcurementVendorItemUtil.sortEvaluationScore(
                              dataProcurement,
                              procurementVendors,
                              procurementVendorItems,
                           )
                           const filtered = sortByWinner.filter((vendor) =>
                              vendor.vendorName.toLowerCase().includes(dataState.query),
                           )
                           setVendorsPerItem({ ...vendorsPerItem, data: filtered })
                        } else {
                           const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(
                              dataProcurement,
                              procurementVendors,
                           )
                           sortByWinner.map((vendor, index) => winnerList.push({ ...vendor, ranking: index + 1 }))
                           const procVendorItemsSortedByWinner = winnerList.map((procVendor) =>
                              procurementVendorItems.find(
                                 (procVendorItem) => procVendorItem.procurementVendorId === procVendor._id,
                              ),
                           )
                           const filtered = procVendorItemsSortedByWinner.filter((vendor) =>
                              vendor.vendorName.toLowerCase().includes(dataState.query),
                           )
                           setVendorsPerItem({ ...vendorsPerItem, data: filtered })
                        }
                        setIsLoading(false)
                     },
                     () => {
                        console.log("Error")
                        setIsLoading(false)
                     },
                  ),
               )
            }
         })
         .catch()
   }
   /**
    * End
    * ----------------
    */

   const RenderStatusLabel = ({ data, dataInvitedVendors }) => {
      const invitationData = dataInvitedVendors?.filteredData?.find(
         (_data) => _data.procurementVendorId === data.procurementVendorId,
      )
      return (
         <>
            <Statuslabel label={invitationData?.status} status={invitationData?.status} styles={{ margin: "0" }} />
         </>
      )
   }

   const ColumnRank = ({ data: current, index }) => {
      if (isInitial) {
         return <TableCell style={{ fontWeight: "700" }}>-</TableCell>
      } else {
         const rankingIndex = winnerVendor.findIndex(
            (vendor) => vendor.procurementVendorId === current.procurementVendorId,
         )
         const isWinner = rankingIndex === 0 && current.saving >= 0 && current.lastPrice > 0
         return (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
               {auctionStatus === "ON_GOING" || auctionStatus === "DONE" ? (
                  isWinner ? (
                     <span style={{ fontWeight: 700 }}>
                        1
                        <img src={Trophy} style={{ marginLeft: "10px" }} />
                     </span>
                  ) : (
                     <span style={{ fontWeight: 700 }}>
                        {current.saving < 0 ? winnerVendor.length : rankingIndex + 1}
                     </span>
                  )
               ) : (
                  <span>-</span>
               )}
            </TableCell>
         )
      }
   }

   const SendAuctionLink = ({ row, onSendingEmail }) => {
      const [timer, setTimer] = useState(0)

      const handleSendingEmail = () => {
         const procurementVendorId = row.procurementVendorId
         onSendingEmail(procurementVendorId)

         let seconds = 10
         setTimer(seconds)
         let timerInterval = setInterval(() => {
            if (seconds <= 0) {
               return clearInterval(timerInterval)
            } else {
               seconds--
               setTimer(seconds)
            }
         }, 1000)
      }

      return (
         <>
            {(timer <= 0 && (
               <a href="javascript:void(0);" onClick={() => handleSendingEmail()}>
                  <img src={iconResendInvitation} alt="" />
               </a>
            )) || (
               <>
                  <span>Available to resend in {timer}</span>
               </>
            )}
         </>
      )
   }

   useEffect(() => {
      if (vendorUnitPriceHistory.length > 0) {
         let totalOffer = 0
         for (const vendorUnitPrice of vendorUnitPriceHistory) {
            console.log(vendorUnitPrice)
            totalOffer += vendorUnitPrice.lastPrice * vendorUnitPrice.item.quantity
         }
         setFinalOffer(totalOffer)
      }
   }, [vendorUnitPriceHistory])

   useEffect(() => {
      dispatch(
         fetchAuctionInvitedVendors({ auctionId, page: 0, limit: 10000, query: "" }, (res) => {
            setDataInvitedVendors(res)
         }),
      )
   }, [])

   // useEffect(() => {
   //    if (auctionItems.data.length > 0) {
   //       const { procurementItemId } = auctionItems.data[0]
   //       // dispatch(
   //       //    getVendorsByProcurementItemId(procurementItemId, (res) => {
   //       //       console.log("HMMM", res)
   //       //    }),
   //       // )
   //       dispatch(
   //          getVendorsByProcurementItemIdAndAuctionId(procurementItemId, auctionId, (res) => {
   //             setVendorsPerItem({ ...vendorsPerItem, data: res })
   //          }),
   //       )
   //    }
   // }, [auctionItems])

   return (
      <>
         <MuiDataTable.CustomTable
            showHeader={true}
            headerComponent={
               <MuiDataTable.Filter
                  withSearchBar={true}
                  handleSearch={(v) => setDataState({ ...dataState, query: v.target.value })}
                  onKeyUp={(v) => v.keyCode === 13 && search()}
               />
            }
         >
            <TableHead>
               <TableRow>
                  <TableCell rowSpan={2} sx={{ whiteSpace: "nowrap" }}>
                     Company Name
                  </TableCell>
                  <TableCell rowSpan={2} sx={{ borderRight: "1px solid #ffffff" }}>
                     Attendance Status
                  </TableCell>
                  {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && (
                     <TableCell
                        className="py-0"
                        colSpan={4}
                        align="center"
                        sx={{
                           borderRight: "1px solid #ffffff",
                           borderBottom: "1px solid #ffffff",
                        }}
                     >
                        Score
                     </TableCell>
                  )}
                  <TableCell className="py-0" colSpan={2} align="center" sx={{ borderBottom: "1px solid #ffffff" }}>
                     Price
                  </TableCell>
                  <TableCell align="center" rowSpan={2} sx={{ borderLeft: "1px solid #ffffff" }}>
                     Saving
                  </TableCell>
                  <TableCell rowSpan={2}>Rank</TableCell>
                  <TableCell rowSpan={2} align="center">
                     Letter Of Attorney
                  </TableCell>
                  <TableCell rowSpan={2} align="center">
                     Resend Link Auction
                  </TableCell>
               </TableRow>
               <TableRow>
                  {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && (
                     <>
                        <TableCell className="py-0">Administration</TableCell>
                        <TableCell className="py-0">Technical</TableCell>
                        <TableCell className="py-0">Price</TableCell>
                        <TableCell className="py-0" sx={{ borderRight: "1px solid #ffffff" }}>
                           Total
                        </TableCell>
                     </>
                  )}
                  <TableCell className="py-0">Indicator</TableCell>
                  <TableCell className="py-0">Last</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {isLoading ? (
                  <TableRow>
                     <TableCell
                        sx={{ background: "#F3F5F4" }}
                        colSpan={dataProcurement.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT ? 8 : 12}
                        align="center"
                     >
                        <Loader speed="fast" content="Getting vendor data..." />
                     </TableCell>
                  </TableRow>
               ) : vendorsPerItem.data?.length > 0 ? (
                  vendorsPerItem.data.map(
                     (v, index) => {
                        return (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                                 "&:hover": {
                                    background: "transparent",
                                 },
                              }}
                           >
                              <TableCell
                                 style={{
                                    fontWeight: "700",
                                    minWidth: "200px",
                                    color: "#454545",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                 }}
                                 //  onClick={() => {
                                 //     const vendorId = v._id
                                 //     dataProcurement?.biddingType ===
                                 //        Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                 //        vendorAuctionHistoryRef.current.show()
                                 //     dispatch(
                                 //        getVendorItems({ vendorId }, (res) => {
                                 //           setVendorUnitPriceHistory(res)
                                 //        }),
                                 //     )
                                 //     const dataVendor = dataProcurementVendors?.filteredData.filter(
                                 //        (data) => data._id == v._id,
                                 //     )
                                 //     setDataAuctionPerVendor(dataVendor[0])
                                 //  }}
                              >
                                 {v.vendorName}
                              </TableCell>
                              <TableCell className="px-0">
                                 {dataInvitedVendors?.filteredData?.length > 0 && (
                                    <RenderStatusLabel data={v} dataInvitedVendors={dataInvitedVendors} />
                                 )}
                              </TableCell>
                              {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT && (
                                 <>
                                    <TableCell className="px-0">
                                       <Statuslabel label="Passed" status="SUCCESS" />
                                    </TableCell>
                                    <TableCell align="right">{v.technicalScore.toFixed(2)}</TableCell>
                                    <TableCell align="right">{v.priceScore?.toFixed(2)}</TableCell>
                                    <TableCell align="right">{v.totalScore?.toFixed(2)}</TableCell>
                                 </>
                              )}
                              <TableCell align="right" sx={{ minWidth: "100px", whiteSpace: "nowrap" }}>
                                 {Formservice.CurrencyFormatter(v.indicatorPrice, {
                                    currency: dataProcurement?.currency,
                                 })}
                              </TableCell>
                              <TableCell align="right" sx={{ minWidth: "100px", whiteSpace: "nowrap" }}>
                                 {Formservice.CurrencyFormatter(v.lastPrice, { currency: dataProcurement?.currency })}
                              </TableCell>
                              <TableCell align="right" style={{ fontWeight: "700" }}>
                                 {isInitial ? (
                                    <span>-</span>
                                 ) : (
                                    <span
                                       style={{
                                          color: v.saving > 0 ? "green" : v.saving < 0 ? "red" : "gray",
                                       }}
                                    >
                                       {v.saving.toFixed(2)}%
                                    </span>
                                 )}
                              </TableCell>
                              <ColumnRank data={v} index={index} />
                              <TableCell align="center">
                                 <a
                                    href="javascript:void(0);"
                                    // onClick={() => {
                                    //    v.documents.forEach((doc, index) => {
                                    //       window.open(doc.url), "_blank"
                                    //    })
                                    // }}
                                 >
                                    <img src={PaperclipIcon} />
                                 </a>
                              </TableCell>
                              <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                                 <SendAuctionLink
                                    row={v}
                                    onSendingEmail={(procurementVendorId) => {
                                       dispatch(resendJoinAuctionEmail(procurementVendorId, auctionId))
                                    }}
                                 />
                              </TableCell>
                           </TableRow>
                        )
                     },
                     // )
                  )
               ) : (
                  <TableRow sx={{ background: "#F3F5F4" }}>
                     {dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? (
                        <TableCell colSpan={12} align="center" sx={{ fontWeight: 500 }}>
                           No records to display
                        </TableCell>
                     ) : (
                        <TableCell colSpan={8} align="center" sx={{ fontWeight: 500 }}>
                           No records to display
                        </TableCell>
                     )}
                  </TableRow>
               )}
            </TableBody>
         </MuiDataTable.CustomTable>

         {/* Vendor auction history modal */}
         <ModalForm passedInRef={vendorAuctionHistoryRef} title="Unit Price" closeButton={true}>
            {" "}
            <div style={{ padding: "0 26px 42px", overflow: "auto", height: "50vh" }}>
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Company
                  </Col>
                  <Col>{dataAuctionPerVendor && dataAuctionPerVendor.name}</Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Indicator Price
                  </Col>
                  <Col>
                     {dataAuctionPerVendor &&
                        Formservice.CurrencyFormatter(dataAuctionPerVendor.indicatorPrice, {
                           currency: dataProcurement?.currency,
                        })}
                  </Col>
               </Row>
               <Row className="mb-4">
                  <Col lg={3} className="px-3">
                     Last Price
                  </Col>
                  <Col>
                     {dataAuctionPerVendor &&
                        Formservice.CurrencyFormatter(dataAuctionPerVendor.lastPrice, {
                           currency: dataProcurement?.currency,
                        })}
                  </Col>
               </Row>
               <MuiDataTable.CustomTable>
                  <TableHead>
                     <TableRow>
                        {historyColumn.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{ whiteSpace: "nowrap" }}
                              align={index === 2 || index === 4 || index === 5 ? "right" : "left"}
                           >
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 IconComponent={ExpandLessIcon}
                                 onClick={() => handleRequestSortHistory(column)}
                              >
                                 {column}
                              </TableSortLabel>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {vendorUnitPriceHistory &&
                        vendorUnitPriceHistory.map((v, index) => (
                           <TableRow key={index} sx={{ background: "#F3F5F4" }}>
                              <TableCell className="py-2">{v.item.itemCode}</TableCell>
                              <TableCell className="py-2">{v.item.itemName}</TableCell>
                              <TableCell className="py-2" align="right">
                                 {v.item.quantity}
                              </TableCell>
                              <TableCell className="py-2">{v.item.uom}</TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.lastPrice, { currency: dataProcurement?.currency })}
                              </TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.lastPrice * v.item.quantity, {
                                    currency: dataProcurement?.currency,
                                 })}
                              </TableCell>
                           </TableRow>
                        ))}
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell
                           colSpan={6}
                           align="right"
                           sx={{
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                           }}
                           className="py-2"
                        >
                           <span className="mr-5">Total Offer</span>
                           {Formservice.CurrencyFormatter(finalOffer, { currency: dataProcurement?.currency })}
                        </TableCell>
                     </TableRow>
                  </TableBody>
               </MuiDataTable.CustomTable>
            </div>{" "}
         </ModalForm>
      </>
   )
}

const mapStateToProps = (state) => ({
   // dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
   auctionItems: state.auctionReducer.auctionItems,
   // vendorsPerItem: state.procurementReducer.vendorsPerItem,
   dataAuctionInvitations: state.auctionReducer.dataAuctionInvitations,
})

export default connect(mapStateToProps)(BuyerAuctionVendorListUnit)
