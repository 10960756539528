import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import styled, { ThemeProvider } from "styled-components"
import Headermenu from "./components/Headermenu.jsx"
import Placeholder from "./components/Placeholder.jsx"
import PrivateRoute from "./components/Privateroute.jsx"
import Sidemenu from "./components/Sidemenu"
import Assetpage from "./modules/Assetpage"
import BuyerAuctionSessionInProgress from "./modules/buyer-auction/BuyerAuctionSessionInProgress"
import BuyerAuctionSessionNotStarted from "./modules/buyer-auction/BuyerAuctionSessionNotStarted"
import Buyerlogin from "./pages/buyer/login/Buyerlogin"
import Vendorlogin from "./modules/Vendorlogin"
import Initiationform from "./modules/initiation/Initiationform"
import Initiationlist from "./modules/initiation/Initiationlist"
import Loginpage from "./modules/Loginpage"
import Onboarding from "./pages/buyer/onboarding/Onboarding"
import Registerpage from "./pages/buyer/register/Registerpage"
import Roompage from "./modules/Roompage"
import VendorAuction from "./modules/vendor-auction/VendorAuction"
import AttendanceConfirmation from "./modules/vendor-auction/AttendanceConfirmation"
import JoinAuction from "./modules/vendor-auction/JoinAuction"
import InitiationAuctionform from "./modules/initiation/auction/InitiationAuctionform.jsx"
import Dashboard from "./pages/buyer/homepage/dashboard/Dashboard"
import AttendanceExpired from "./modules/vendor-auction/AttendanceExpired.jsx"
import NotAttendAuction from "./modules/vendor-auction/NotAttendAuction.jsx"
import VendorAuctionHistory from "./modules/initiation/VendorAuctionHistory.jsx"
import NegotiationUnitPrice from "./modules/initiation/negotiation/NegotiationUnitPrice.jsx"
import NegotiationPage from "./pages/buyer/homepage/procurement/negotiation/component/Negotiation"
import BuyerHelpPage from "./pages/buyer/homepage/help/BuyerHelpPage"
import VendorHelpPage from "./pages/vendor/help/VendorHelpPage"
import LoginAuction from "./pages/buyer/homepage/help/help-contents/LoginAuction"
import Registration from "./pages/buyer/homepage/help/help-contents/Registration"
import CreateInitiation from "./pages/buyer/homepage/help/help-contents/CreateInitiation"
import EndInitiation from "./pages/buyer/homepage/help/help-contents/EndInitiation"
import CreateBuyerAuction from "./pages/buyer/homepage/help/help-contents/CreateBuyerAuction"
import StartVendorAuction from "./pages/vendor/help/help-content/StartVendorAuction"
import NegotiationProcess from "./pages/buyer/homepage/help/help-contents/NegotiationProcess"
import ReNegotiationProcess from "./pages/buyer/homepage/help/help-contents/ReNegotiationProcess"
import Report from "./pages/buyer/homepage/help/help-contents/Report"
import LandingPage from "./pages/buyer/landing-page/Landingpage"
import Chat from "./modules/Chat"
import BuyerNegotiationOnline from "./modules/initiation/negotiation/buyer/buyer-nego-online.module.jsx"
import VendorNegotiationOnline from "./modules/initiation/negotiation/vendor/vendor-nego-online.module.jsx"
import axiosConfig from "../config/application/axios.config.jsx"
import CheckLanguage from "./middleware/CheckLanguage.jsx"
import ForgotPassword from "./modules/authentication/Forgotpass"
import ChangePassword from "./modules/authentication/Changepass"

function App({ isSidemenuExpanded }) {
   const [loading, setLoading] = useState(true)

   // colors
   const colors = {
      pristine: "#2EA996",
      pristineSat40: "#03D4B4",
      pristineLig40: "#A5FEF0",
      pristineGrey: "#F3F5F4",
      pristineLightGrey: "#F3F7F9",

      red: "#C71822",
      accentRed: "#F44437",
      yellow: "#FFD84D",
      yellowDarken: "#DFA100",

      white: "#FFFFFF",
      whiteDarken15: "#D9D9D9",
      whiteDarken30: "#909090",

      greyDarken: "#1E1E1E",
      greyDarken30: "#474747",

      blue: "#5DBCF9",
      blueGreyLight: "#F3F7F9",
      blueOcean: "#0772B6",

      green: "#4DAF51",

      pink: "#FFC6C9",

      purple: "#BB6BD9",
   }

   // theme
   const theme = {
      colors: colors,

      fonts: {
         regular: "Roboto, sans-serif",
         buttons: "Open Sans, sans-serif",
         sizes: {
            h1: "24px",
            regular: "14px",
            formLabel: "14px",
            formValue: "14px",
            formHelper: "12px",
            buttonLg: "14px",
         },
      },

      borderRadius: {
         big: "45px",
         normal: "12px",
         small: "5px",
      },

      sidemenu: {
         bgColor: colors.pristineGrey,
         fontColor: colors.pristine,
         logoBorder: colors.whiteDarken15,
      },

      sidemenuSeller: {
         bgColor: colors.blueGreyLight,
         fontColor: colors.blueOcean,
         logoBorder: colors.whiteDarken15,
      },

      headermenu: {
         fontColor: colors.whiteDarken30,
         fontColorHovered: colors.pristine,
      },
   }

   useEffect(() => {
      // initiateSocketConnection()
      axiosConfig.initAxiosConfig()
      setLoading(false)
   }, [])

   return (
      <>
         <Router>
            <ThemeProvider theme={theme}>
               <Toaster />
               <ContentWrapper loading={loading} expandSidemenu={isSidemenuExpanded} />
            </ThemeProvider>
         </Router>
      </>
   )
}

function ContentWrapper({ loading, expandSidemenu }) {
   return (
      <>
         {location.pathname.startsWith("/buyer-login") ||
            location.pathname.startsWith("/forgot-password") ||
            location.pathname.startsWith("/vendor-login") ||
            location.pathname.startsWith("/vendor-auction") ||
            location.pathname.startsWith("/register") ||
            location.pathname.startsWith("/onboarding") ||
            location.pathname.startsWith("/notfound") ||
            location.pathname.startsWith("/help/vendor") ||
            location.pathname.startsWith("/chat") ||
            location.pathname.includes("/negotiation/session-online/vendor/") ||
            location.pathname.length == 1 || (
               <Sidemenu
               // minimize={minimizeDrawer}
               // onDrawerClicked={() => handleDrawerClicked()}
               />
            )}
         <ContentSection
            onboarding={
               location.pathname.startsWith("/onboarding") ||
               location.pathname.startsWith("/help/vendor") ||
               location.pathname.length == 1
            }
            register={location.pathname.startsWith("/register")}
            buyerlogin={location.pathname.startsWith("/buyer-login")}
            vendorlogin={location.pathname.startsWith("/vendor-login")}
            vendorauction={location.pathname.startsWith("/vendor-auction")}
            chat={location.pathname.startsWith("/chat")}
            notattend={location.pathname.includes("/not-attend")}
            className={!expandSidemenu ? "expand" : ""}
            vendorNegoOnline={location.pathname.includes("/negotiation/session-online/vendor")}
            forgotPassword={location.pathname.includes("/forgot-password")}
         >
            {(loading && <Placeholder />) || (
               <>
                  {(location.pathname.startsWith("/vendor-auction") && !location.pathname.includes("/not-attend")) ||
                  location.pathname.includes("/help/vendor") ||
                  location.pathname.includes("/negotiation/session-online/vendor") ? (
                     <Headermenu fullwidth="true" isVendor showMenus />
                  ) : !location.pathname.startsWith("/onboarding") &&
                    !location.pathname.startsWith("/login") &&
                    !location.pathname.startsWith("/buyer-login") &&
                    !location.pathname.startsWith("/forgot-password") &&
                    !location.pathname.startsWith("/chat") &&
                    !location.pathname.startsWith("/vendor-login") &&
                    !location.pathname.startsWith("/register") &&
                    !location.pathname.startsWith("/notfound") &&
                    !location.pathname.includes("/not-attend") &&
                    location.pathname.length > 1 ? (
                     <Headermenu expand={!expandSidemenu} />
                  ) : (
                     <></>
                  )}

                  <Switch>
                     <PrivateRoute path="/" exact component={LandingPage} expand={!expandSidemenu} />

                     <PrivateRoute path="/dashboard" exact component={Dashboard} expand={!expandSidemenu} />
                     <Route path="/chat/:roomId" exact component={Chat} expand={!expandSidemenu} />
                     <Route path="/login" exact component={Loginpage} expand={!expandSidemenu} />
                     <PrivateRoute path="/buyer-login" exact component={CheckLanguage(Buyerlogin)} expand={!expandSidemenu} />
                     <Route path="/vendor-login" exact component={Vendorlogin} expand={!expandSidemenu} />
                     <Route path="/register" exact component={Registerpage} expand={!expandSidemenu} />
                     <PrivateRoute path="/initiation/form" exact component={Initiationform} expand={!expandSidemenu} />
                     <PrivateRoute
                        path="/initiation/form/:procurementId"
                        exact
                        component={CheckLanguage(Initiationform)}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/negotiation/session-online/buyer/:negotiationBatchId/:negotiationId"
                        exact
                        component={BuyerNegotiationOnline}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/negotiation/session-online/vendor/:negotiationBatchId/:negotiationId"
                        exact
                        component={VendorNegotiationOnline}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/initiation/:procurementId/vendor/auction/history/"
                        exact
                        component={VendorAuctionHistory}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/initiation/:procurementId/vendor/unit/negotiation/"
                        exact
                        component={NegotiationUnitPrice}
                        expand={!expandSidemenu}
                     />
                     <PrivateRoute
                        path="/initiation/form/:procurementId/auction/form"
                        exact
                        component={InitiationAuctionform}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/buyer-auction/:procurementId/:auctionId/not-started"
                        exact
                        component={BuyerAuctionSessionNotStarted}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/buyer-auction/:procurementId/:auctionId/in-progress"
                        exact
                        component={BuyerAuctionSessionInProgress}
                        expand={!expandSidemenu}
                     />
                     <PrivateRoute path="/onboarding" exact component={Onboarding} expand={!expandSidemenu} />
                     <PrivateRoute path="/initiation" exact component={Initiationlist} expand={!expandSidemenu} />
                     <Route path="/secured/room" exact component={Roompage} expand={!expandSidemenu} />
                     <Route path="/vendor-auction/join" exact component={JoinAuction} expand={!expandSidemenu} />
                     <PrivateRoute
                        path="/vendor-auction/session/:auctionId"
                        exact
                        component={CheckLanguage(VendorAuction)}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/vendor-auction/attendance-confirmation/:procurementId/:verificationCode"
                        exact
                        component={AttendanceConfirmation}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/vendor-auction/attendance-expired"
                        exact
                        component={AttendanceExpired}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/vendor-auction/not-attend/:auctionId/:invitationId"
                        exact
                        component={NotAttendAuction}
                     />
                     <PrivateRoute path="/negotiation/session/:procurementId/new" exact component={NegotiationPage} />
                     <PrivateRoute path="/negotiation/session/:procurementId/:batchId" exact component={NegotiationPage} />
                     <Route path="/help/buyer" exact component={BuyerHelpPage} expand={!expandSidemenu} />
                     <Route path="/help/vendor" exact component={VendorHelpPage} expand={!expandSidemenu} />
                     <Route path="/help/login-auction" exact component={LoginAuction} expand={!expandSidemenu} />
                     <Route path="/help/registration" exact component={Registration} expand={!expandSidemenu} />
                     <Route
                        path="/help/create-initiation"
                        exact
                        component={CreateInitiation}
                        expand={!expandSidemenu}
                     />
                     <Route path="/help/end-initiation" exact component={EndInitiation} expand={!expandSidemenu} />
                     <Route path="/help/create-auction" exact component={CreateBuyerAuction} expand={!expandSidemenu} />
                     <Route
                        path="/help/start-vendor-auction"
                        exact
                        component={StartVendorAuction}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/help/negotiation-process"
                        exact
                        component={NegotiationProcess}
                        expand={!expandSidemenu}
                     />
                     <Route
                        path="/help/renegotiation-process"
                        exact
                        component={ReNegotiationProcess}
                        expand={!expandSidemenu}
                     />
                     <Route path="/help/report" exact component={Report} expand={!expandSidemenu} />
                     <Route path="/forgot-password" exact component={ForgotPassword} expand={!expandSidemenu} />
                     <PrivateRoute path="/account/change-password" exact component={ChangePassword} expand={!expandSidemenu} />
                  </Switch>
               </>
            )}
         </ContentSection>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProfile: state.sessionReducer.dataProfile,
      isSidemenuExpanded: state.appReducer.isSidemenuExpanded,
      // expandSidemenu: true
   }
}

export default connect(mapStateToProps)(App)

const ContentSection = styled(Container).attrs((props) => {
   className: props.className
})`
   transition: ease-in-out 0.3s all;
   padding-left: ${(props) =>
      props.register ||
      props.buyerlogin ||
      props.forgotPassword ||
      props.vendorlogin ||
      props.vendorauction ||
      props.onboarding ||
      props.vendorNegoOnline
         ? "0"
         : "237px"};
   margin-right: auto;
   width: 100%;
   padding-right: ${(props) =>
      props.register ||
      props.buyerlogin ||
      props.vendorlogin ||
      props.vendorauction ||
      props.onboarding ||
      props.forgotPassword
         ? "0"
         : "30px"};
   max-width: inherit;
   padding-top: ${(props) =>
      props.register ||
      props.buyerlogin ||
      props.vendorlogin ||
      props.vendorauction ||
      props.onboarding ||
      props.forgotPassword
         ? "0"
         : "73px"};

   &.expand {
      /* margin-left: 44px; */
      padding-left: 90px;
   }
`
