import { orderBy as _orderBy, omit as _omit } from "lodash"
import Constant from "../../../constant"

const convertEvaluationScoreType = (evalScore) => {
   if (evalScore === Constant.EVALUATION_SCORE.ADMINSITRATION_VALUE) {
      return "adminstrationScore"
   } else if (evalScore === Constant.EVALUATION_SCORE.PRICE_VALUE) {
      return "priceScore"
   } else if (evalScore === Constant.EVALUATION_SCORE.TECHNICAL_VALUE) {
      return "technicalScore"
   }
   return null
}

const sortEvaluationScore = (procurement, procurementVendor) => {
   let orderResult
   orderResult = []
   if (procurement.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT) {
      const evalScore = _omit(procurement.evaluationScoring, "_id")
      const sortEvalScore = []
      for (const x in evalScore) {
         sortEvalScore.push([x, evalScore[x]])
      }
      sortEvalScore.sort(function (a, b) {
         return b[1] - a[1]
      })
      const keyScore = convertEvaluationScoreType(sortEvalScore[0][0])

      // find vendor with minus saving
      const minusSavingVendors = procurementVendor.filter((vendor) => vendor.saving < 0)

      if (procurementVendor.length > 2) {
         // do this condition only if auction participant is more than 2 vendors
         // this case is for closed auction

         if (minusSavingVendors.length > 0) {
            // 1. filter and order vendors without minus saving
            const orderVendorWithoutMinusSaving = _orderBy(
               procurementVendor,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            ).filter((vendor) => vendor.saving >= 0)

            // 2. filter and order only for minus saving vendor
            const orderVendorMinusSaving = _orderBy(
               minusSavingVendors,
               ["totalScore", "lastBidTime", keyScore, "name"],
               ["desc", "asc", "desc", "asc"],
            )

            // 3. merge point 1 & 2 
            orderResult = [...orderVendorWithoutMinusSaving, ...orderVendorMinusSaving]
         } else {
            // if no minus saving vendors
            orderResult = _orderBy(procurementVendor, ["totalScore", "lastBidTime", keyScore, "name"], ["desc", "asc", "desc", "asc"])
         }
      } else { // if only 2 vendors
         if (minusSavingVendors.length > 0) {
            // if there are one or two vendors with minus saving
            const vendorWithoutMinusSaving = procurementVendor.filter((vendor) => vendor.saving >= 0)
            
            // put vendor without minus saving on top list and the rest is for minus saving
            orderResult = [...vendorWithoutMinusSaving, ...minusSavingVendors]
         } else {
            orderResult = _orderBy(procurementVendor, ["totalScore", "lastBidTime", keyScore, "name"], ["desc", "asc", "desc", "asc"])
         }
      }
   } else if (procurement.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT) {
      const _procurementVendor = procurementVendor.map((vendor) => {
         if (vendor.lastBidTime === null) {
            vendor.lastBidTime = new Date(9999, 11, 31, 23, 59, 59).getTime()
         } else {
            vendor.lastBidTime = new Date(vendor.lastBidTime).getTime()
         }
         return vendor
      })

      const includeZeroBid = _procurementVendor.find((v) => v.lastPrice === 0)
      const bidOverOneVendor = _procurementVendor.filter((v) => v.lastPrice > 0).length > 1
      if (bidOverOneVendor && includeZeroBid) {
         // process for if more than one vendor bid exist and another has not already bid

         if (procurementVendor.length > 2) { // if more than two vendors
            const minusSavingVendors = _procurementVendor.filter((vendor) => vendor.saving < 0)
            if (minusSavingVendors.length > 0) { // if there are 1 or 2 vendor with minus saving
               // 1. find vendor without minus saving and 0 bid
               const vendorWithoutMinusSavingAndZeroBid = _procurementVendor.filter(
                  (v) => v.saving >= 0 && v.lastPrice > 0,
               )

               // 2. order vendor without minus saving and 0 bid
               const orderVendorWithoutMinusSavingAndZeroBid = _orderBy(
                  vendorWithoutMinusSavingAndZeroBid,
                  ["lastPrice", "lastBidTime", "name"],
                  ["asc", "asc", "asc"],
               )
               
               // 3. filter zero bid
               const _zeroBid = _procurementVendor.filter((v) => v.lastPrice == 0)
               
               // 4. order vendors with minus saving 
               const orderVendorMinusSaving = _orderBy(minusSavingVendors, ["lastPrice", "lastBidTime", "name"], ["asc", "asc", "asc"])

               // 5. merge and put vendor without minus saving and 0 bid at the top list array with zero at the middle and minus on the rest
               orderResult = [...orderVendorWithoutMinusSavingAndZeroBid, ..._zeroBid, ...orderVendorMinusSaving]
            } else { // if 
               // sort from lowest last price & remove vendor with 0 last price
               const newOrderResult = _orderBy(_procurementVendor, ["lastPrice"], ["asc"]).filter(
                  (v) => v.lastPrice > 0,
               )

               const _noZeroBid = _orderBy(newOrderResult, ["lastPrice", "lastBidTime"], ["asc", "asc"])
               const _zeroBid = _procurementVendor.filter((v) => v.lastPrice == 0)

               orderResult = [..._noZeroBid, ..._zeroBid]
            }
         } else if (procurementVendor.length < 3) { // if only 2 vendors
            orderResult = _orderBy(_procurementVendor, ["saving", "lastBidTime", "name"], ["desc", "asc", "asc"])
         }
      } else if (includeZeroBid) {
         // process for if only just one bid exist

         const minusSavingVendors = _procurementVendor.filter((vendor) => vendor.saving < 0) // get vendors with minus saving
         if (procurementVendor.length > 2) { // for more than 2 vendors
            if (minusSavingVendors.length > 0) { // if there are vendors with minus saving
               // 1. filter and order vendors without minus saving
               const orderVendorWithoutMinusSaving = _orderBy(
                  _procurementVendor,
                  ["lastPrice", "lastBidTime", "name"],
                  ["asc", "asc", "asc"],
               ).filter((v) => v.saving >= 0)
               
               // 2. filter and order only for minus vendors
               const orderVendorMinusSaving = _orderBy(minusSavingVendors, ["lastPrice", "lastBidTime", "name"], ["asc", "asc", "asc"])

               // 3. merge and place order vendors without minus saving above order vendors with minus saving
               orderResult = [...orderVendorWithoutMinusSaving, ...orderVendorMinusSaving]
            } else { // if no vendors with minus saving
               orderResult = _orderBy(_procurementVendor, ["lastPrice", "lastBidTime", "name"], ["desc", "asc", "asc"])
            }
         } else {
            if (minusSavingVendors.length > 0) { // for only two vendors which one of them has minus saving
               const vendorWithoutMinusSaving = _procurementVendor.filter((vendor) => vendor.saving >= 0)

               // put vendor without minus saving on top list and the rest is for minus saving
               orderResult = [...vendorWithoutMinusSaving, ...minusSavingVendors]
            } else { // if no minus saving with two vendors
               orderResult = _orderBy(
                  _procurementVendor,
                  ["saving", "lastPrice", "lastBidTime", "name"],
                  ["desc", "asc", "asc", "asc"],
               )
            }
         }
      } else {
         // process if all vendor already bid
         orderResult = _orderBy(_procurementVendor, ["saving", "lastPrice", "lastBidTime", "name"], ["desc", "asc", "asc", "asc"])
      }
   }
   return orderResult
}

const ProcurementVendorUtil = {
   sortEvaluationScore,
}

export default ProcurementVendorUtil
