import { faInfoCircle, faPlusCircle, faDownload } from "@fortawesome/free-solid-svg-icons"
import React, { useEffect, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import LogisticIcon from "../../../assets/images/LogisticIcon.png"
import Buttonaction from "../../../components/Buttonaction"
import Datatable from "../../../components/Datatable"
import { GreyCard } from "../../../components/styled/Generalcard"
import {
   deleteProcurementItem,
   fetchProcurementAuctions,
   fetchProcurementItems,
   getProcurementAuction,
   getProcurement,
   setProcurementItemsState,
} from "../../../redux/action/procurementAction"
import InitiationItemForm from "../item/InitiationItemform"
import Formservice from "../../../services/Formservice"
import { Checkbox, Whisper, Tooltip as Tooltips } from "rsuite"
import InfoRoundIcon from "@rsuite/icons/InfoRound"
import Statuslabel from "../../../components/Statuslabel"
import Dateinfo from "../../../components/Dateinfo"
import { deleteAuctions } from "../../../redux/action/procurementAuctionAction"
import SubmitModal from "../../../components/Submitmodal"
import Chip from "../../../components/Chip"
import Timeinfo from "../../../components/Timeinfo"
import MuiDataTable from "../../../components/MuiDataTable"
import {
   TableBody,
   TableCell,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
   Tooltip,
   Checkbox as MuiCheckbox,
} from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { orderBy as _orderBy } from "lodash"
import toast from "react-hot-toast"
import { format, isAfter, isBefore } from "date-fns"
import Constant from "../../../../constant"
import {
   getBniAuctionReport,
   getGenerateReportAuctionData,
   createGenerateReportAuctionData,
   updateGenerateReportAuctionData,
} from "../../../redux/action/reportAction"
import { triggerBase64Download } from "common-base64-downloader-react"
import { saveAs } from "file-saver"
import ModalForm from "../../../components/Modalform"
import PencilBlue from "../../../assets/images/PencilSimpleBlue.svg"
import CloseButtonSolidBlue from "../../../assets/images/CloseButtonSolidBlue.svg"

function InitiationAuctionList({
   // dataProcurementAuctions,
   isAppLoading,
   readOnly = false,
   passedInRef,
   handleExpand,
   procurementHasEnded,
   onCreateSession,
   companyId,
}) {
   const dispatch = useDispatch()
   const history = useHistory()
   const modalItemFormRef = useRef()
   const modalConfirmRef = useRef()
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
      selectedRows: [],
   })
   const [dataProcurementAuctions, setDataProcurementAuctions] = useState(undefined)
   const { procurementId } = useParams()
   const [selectedAuctions, setSelectedAuctions] = useState({
      procurementId: procurementId,
      auctionIds: [],
   })
   const modalDownloadAuctionReport = useRef()
   const [payloadAuctionReport, setPayloadAuctionReport] = useState({
      organizingCommittee: "",
      allocation: "",
   })
   const [dataAuctionReport, setDataAuctionReport] = useState(null)
   const [editOrganizingCommittee, setEditOrganizingCommittee] = useState(false)
   const [editAllocation, setEditAllocation] = useState(false)

   const viewDetail = async (row) => {
      if (row.status === "ON_GOING") {
         history.push(`/buyer-auction/${row.procurementId}/${row._id}/in-progress`)
      } else if (row.status === "DONE" || row.status === "NOT_STARTED" || row.status === "CANCELED") {
         history.push(`/buyer-auction/${row.procurementId}/${row._id}/not-started`)
      } else if (row.status === "DRAFT") {
         localStorage.setItem("auctionId", row._id)
         history.push(`/initiation/form/${row.procurementId}/auction/form`)
         // dispatch(getProcurementAuction({auctionId: row._id}, (res) => console.log(res)))
      }
   }

   const beforeRemove = async () => {
      modalConfirmRef.current.show({
         title: "Delete these auction?",
         modalTitle: "",
         description: "You will delete this auctions. Are you sure?",
      })
   }

   const search = ({ target }) => {
      const { value } = target

      dispatch(
         fetchProcurementAuctions(
            {
               procurementId,
               page: 1,
               limit: dataState.limit,
               query: value,
            },
            (res) => {
               res.total === 0 ? setDataProcurementAuctions(res) : setDataProcurementAuctions(res)
            },
         ),
      )
      setDataState((state) => ({
         ...state,
         query: value,
      }))
   }

   const [dataProcurement, setDataProcurement] = useState({})
   useEffect(() => {
      dispatch(
         getProcurement(
            { procurementId },
            (res) => {
               setDataProcurement(res)
            },
            () => {
               toast.error("Data not found")
            },
         ),
      )

      dispatch(
         fetchProcurementAuctions(
            {
               procurementId,
               page: 1,
               limit: dataState.limit,
            },
            (res) => {
               setDataProcurementAuctions(res)
            },
         ),
      )
   }, [])

   useEffect(() => {
      if (companyId) {
         dispatch(
            getGenerateReportAuctionData(
               companyId,
               (res) => {
                  console.log("REPORT AUCTION DATA", res)
                  setDataAuctionReport(res)
                  if (res) {
                     setPayloadAuctionReport(res)
                  }
               },
               () => {},
            ),
         )
      }
   }, [companyId])

   /**
    * table handling
    * ----------------
    */
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")
   const tableColumns = ["Auction Number", "Auction Type", "Start Time", "End Time", "Status"]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Auction Number"
            ? "auctionNumber"
            : column === "Auction Type"
            ? "auctionType"
            : column === "Start Time"
            ? "startTime"
            : column === "End Time"
            ? "endTime"
            : column === "Status" && "status"

      let result = []
      result = _orderBy(dataProcurementAuctions.filteredData, [forColumn], [_order])

      // dispatch(setDataProcurementAuctions(result));
      setDataProcurementAuctions({
         ...dataProcurementAuctions,
         filteredData: result,
      })
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurementAuctions(
            {
               ...dataState,
               procurementId,
               limit: value,
            },
            (res) => {
               setDataProcurementAuctions(res)
               setDataState({
                  ...dataState,
                  limit: value,
               })
               // createItemsChecked(res.filteredData);
               // setAllDefault();
            },
         ),
      )
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         fetchProcurementAuctions(
            {
               ...dataState,
               procurementId,
               page: newPage + 1,
            },
            (res) => {
               setDataProcurementAuctions(res)
               setDataState({ ...dataState, page: newPage })
               // createItemsChecked(res.filteredData);
               // setAllDefault();
            },
         ),
      )
   }

   const handleRemoveAuction = () => {
      if (selectedAuctions.auctionIds.length > 0) {
         if (confirm("Are you sure to delete these Auction(s)?")) {
            dispatch(
               deleteAuctions(selectedAuctions, () => {
                  toast.success("Auctions Removed Successfully")
                  setSelectedAuctions({
                     ...selectedAuctions,
                     auctionIds: [],
                  })
                  dispatch(
                     fetchProcurementAuctions({ ...dataState, procurementId }, (res) => {
                        res.filteredData.length < 1 ? setDataProcurementAuctions(res) : setDataProcurementAuctions(res)

                        // setAllDefault();
                     }),
                  )
               }),
            )
         }
      } else {
         toast.error("No Auctions Selected")
      }
   }

   const handleSelectAuction = (v, index) => {
      const checked = v.target.checked
      // let newVendorsChecked = [...vendorsChecked];

      if (checked) {
         setSelectedAuctions({
            ...selectedAuctions,
            auctionIds: [...selectedAuctions.auctionIds, v.target.value],
         })
         // newVendorsChecked[index].checked = true;
         // setVendorsChecked(newVendorsChecked);
         // findIndeterminate();
         // allVendorChecked();
      }

      if (!checked) {
         const newSelectedAuctions = selectedAuctions.auctionIds.filter((auctionId) => auctionId != v.target.value)

         setSelectedAuctions({
            ...selectedAuctions,
            auctionIds: [...newSelectedAuctions],
         })

         // newVendorsChecked[index].checked = false;
         // setVendorsChecked(newVendorsChecked);
         // findIndeterminate();
         // allVendorChecked();
      }
   }

   /**
    * end
    * ----------------
    */
   const [downloading, setDownloading] = useState(false)
   const handleGenerateReport = (payload) => {
      setDownloading(true)

      let pelaksana = null
      let peruntukan = null
      if (payload) {
         pelaksana = payload.organizingCommittee
         peruntukan = payload.allocation
      } else if (dataAuctionReport && !dataProcurement.promiseProcurementId) {
         pelaksana = dataAuctionReport.organizingCommittee
         peruntukan = dataAuctionReport.allocation
      }

      // handle for standalone
      if (!dataProcurement.promiseProcurementId) {
         if (!dataAuctionReport) {
            // do create
            dispatch(
               createGenerateReportAuctionData(
                  { companyId, ...payloadAuctionReport },
                  (res) => {
                     console.log("CREATE SUCCESS", res)
                     setDataAuctionReport({ organizingCommittee: res.organizingCommittee, allocation: res.allocation })
                  },
                  (err) => console.log("CREATE FAILED", err),
               ),
            )
         }

         if (editOrganizingCommittee || editAllocation) {
            // do update
            dispatch(
               updateGenerateReportAuctionData(
                  companyId,
                  payloadAuctionReport,
                  (res) => {
                     console.log("UPDATE SUCCESS", res)
                     setDataAuctionReport((prev) => ({
                        ...prev,
                        organizingCommittee: res.organizingCommittee,
                        allocation: res.allocation,
                     }))
                     setEditOrganizingCommittee(false)
                     setEditAllocation(false)
                  },
                  (err) => {
                     console.log("UPDATE FAILED", err)
                     setEditOrganizingCommittee(false)
                     setEditAllocation(false)
                  },
               ),
            )
         }
      }

      new Promise((resolve, reject) => {
         dispatch(
            getBniAuctionReport(
               { procurementId, pelaksana, peruntukan },
               (resp) => {
                  // docx
                  // resolve(
                  //    `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${resp.data}`,
                  // )

                  // pdf
                  resolve(resp)
               },
               (err) => reject(err),
            ),
         )
      })
         .then((resp) => {
            var blob = new Blob([resp], { type: "octet/stream" })
            let fileName = "BA_E_AUCTION.pdf"
            saveAs(blob, fileName)

            // triggerBase64Download(base64, "BA_E_AUCTION " + format(new Date(Date.now()), "dd-MMMM-yyyy kk:mm:ss"))
         })
         .catch(() => {
            toast.error("No report generated!")
         })
         .finally(() => {
            setDownloading(false)
            modalDownloadAuctionReport.current.hide()
         })
   }

   return (
      <div
         ref={passedInRef}
         id="auction"
         onMouseEnter={() => {
            if (handleExpand) handleExpand({ expand: "auction" })
         }}
      >
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Auction Session
                        </p>
                        <div className="d-flex">
                           {/* The code below is only for BNI purpose */}
                           <Buttonaction
                              id="download-report"
                              blueOcean
                              disabled={!dataProcurementAuctions?.totalDoneRealtimeAuctions || downloading}
                              label="Download Report"
                              faIcon={faDownload}
                              onClick={() => {
                                 dataProcurement?.promiseProcurementId
                                    ? handleGenerateReport()
                                    : modalDownloadAuctionReport.current.show()
                              }}
                              loading={downloading}
                              loadingLabel="Downloading..."
                           />
                           {/* ===================================== */}
                           {procurementHasEnded ? (
                              <Tooltip
                                 title="Can't create auction due to procurement has ended"
                                 arrow
                                 placement="left"
                                 sx={{ whiteSpace: "nowrap", width: "50px" }}
                              >
                                 <div className="ml-3">
                                    <Buttonaction disabled blueOcean label="New Session" faIcon={faPlusCircle} />
                                 </div>
                              </Tooltip>
                           ) : !readOnly ? (
                              <div className="ml-3">
                                 <Buttonaction
                                    blueOcean
                                    label="New Session"
                                    faIcon={faPlusCircle}
                                    onClick={() =>
                                       onCreateSession
                                          ? onCreateSession()
                                          : history.push(`/initiation/form/${procurementId}/auction/form`)
                                    }
                                 />
                              </div>
                           ) : (
                              <div className="ml-3">
                                 <Buttonaction disabled blueOcean label="New Session" faIcon={faPlusCircle} />
                              </div>
                           )}
                        </div>
                     </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />

                  {(dataProcurementAuctions?.totalAuctions === 0 && (
                     <Row>
                        <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                           <img src={LogisticIcon} />
                           <span
                              className="mt-3"
                              style={{
                                 textAlign: "center",
                                 color: "#909090",
                                 fontWeight: "bold",
                              }}
                           >
                              No session available
                           </span>
                        </Col>
                     </Row>
                  )) || (
                     <MuiDataTable.CustomTable
                        showHeader={true}
                        headerComponent={
                           <MuiDataTable.Filter
                              withSearchBar={true}
                              handleSearch={(v) =>
                                 setDataState({
                                    ...dataState,
                                    query: v.target.value,
                                 })
                              }
                              onKeyUp={(v) => v.keyCode === 13 && search(v)}
                           />
                        }
                        itemsSelectedComponent={
                           <MuiDataTable.ItemsSelected
                              showDeleteBtn={selectedAuctions.auctionIds.length > 0}
                              selectedItems={selectedAuctions.auctionIds.length}
                              onClickDelete={handleRemoveAuction}
                           />
                        }
                        footerComponent={
                           <TablePagination
                              sx={{ background: "#ffffff", borderBottom: "none" }}
                              page={dataState.page}
                              count={dataProcurementAuctions?.total}
                              rowsPerPage={dataState.limit}
                              rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                              // ActionsComponent={TablePaginationActions}
                              className="d-flex justify-content-end"
                              // sx={{ width: "100%", display: "flex" }}
                           />
                        }
                     >
                        <TableHead>
                           <TableRow>
                              <TableCell size="small">
                                 <MuiCheckbox
                                    size="small"
                                    // checked={allChecked}
                                    // indeterminate={indeterminate}
                                    // onChange={handleCheckAll}
                                    className="p-0"
                                 />
                              </TableCell>
                              {tableColumns.map((column, index) => (
                                 <TableCell key={index} className="py-2" sx={{ whiteSpace: "nowrap" }}>
                                    <TableSortLabel
                                       active={orderBy === column}
                                       direction={order}
                                       onClick={() => handleRequestSort(column)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       {column}
                                    </TableSortLabel>
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {dataProcurementAuctions?.filteredData.length > 0 ? (
                              dataProcurementAuctions?.filteredData.map((auction, index) => (
                                 <TableRow
                                    key={index}
                                    sx={{
                                       background: "#F3F5F4",
                                       "&:hover": {
                                          background: "transparent",
                                       },
                                    }}
                                    style={{ cursor: "pointer" }}
                                    onClick={(v) => {
                                       console.log({ v: v.target.id })
                                       v.target.type !== "checkbox" && v.target.id !== "download-report"
                                          ? viewDetail(auction)
                                          : // ? ""
                                            ""
                                    }}
                                 >
                                    <TableCell size="small" className="py-2">
                                       <MuiCheckbox
                                          className="p-0"
                                          size="small"
                                          disabled={auction.status !== "DRAFT"}
                                          sx={{
                                             display: auction.status !== "DRAFT" && "none",
                                          }}
                                          onChange={(v) => handleSelectAuction(v, index)}
                                          value={auction._id}
                                          // checked={
                                          //   itemsChecked[index] &&
                                          //   itemsChecked[index].checked
                                          // }
                                       />
                                    </TableCell>
                                    <TableCell className="py-2">{auction.auctionNumber}</TableCell>
                                    <TableCell className="py-2">
                                       <Chip
                                          className="m-0"
                                          kind={auction.auctionType && auction.auctionType.toLowerCase()}
                                       >
                                          {auction.auctionType == "CLOSE"
                                             ? "Closed"
                                             : auction.auctionType == "OPEN"
                                             ? "Open"
                                             : "Unset"}
                                       </Chip>
                                    </TableCell>
                                    <TableCell className="py-2">
                                       <Dateinfo date={auction.startTime} slashed format="dd/mm/yyyy" />
                                       <span style={{ color: "#030303" }}>
                                          {auction.startTime &&
                                             new Date(auction.startTime).toLocaleString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                             })}
                                       </span>
                                    </TableCell>
                                    <TableCell className="py-2">
                                       <Dateinfo date={auction.endTime} slashed format="dd/mm/yyyy" />
                                       <span style={{ color: "#030303" }}>
                                          {auction.endTime &&
                                             new Date(auction.endTime).toLocaleString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: false,
                                             })}
                                       </span>
                                    </TableCell>
                                    <TableCell className="py-2">
                                       <div className="d-flex align-items-center">
                                          <Statuslabel className="m-0" label={auction.status} status={auction.status} />
                                          {auction.stopReason?.length > 0 && (
                                             <Whisper
                                                placement="left"
                                                speaker={<Tooltips>It was manually stopped</Tooltips>}
                                             >
                                                <InfoRoundIcon
                                                   height={15}
                                                   width={15}
                                                   color="#DFA100"
                                                   className="ml-2"
                                                />
                                             </Whisper>
                                          )}
                                       </div>
                                    </TableCell>
                                 </TableRow>
                              ))
                           ) : (
                              <TableRow sx={{ background: "#F3F5F4" }}>
                                 <TableCell colSpan={6} align="center" sx={{ fontWeight: 500 }}>
                                    No records to display
                                 </TableCell>
                              </TableRow>
                           )}
                        </TableBody>
                     </MuiDataTable.CustomTable>
                  )}
               </GreyCard>
            </Col>
         </Row>

         <InitiationItemForm passedInRef={modalItemFormRef} />
         {/* <SubmitModal.ModalContainer passedInRef={modalConfirmRef}>
        <SubmitModal.ModalButtonActions>
          <Col lg={5}>
            <Buttonaction
              blueOcean
              type="button"
              label="Yes"
              large
              onClick={remove}
            />
          </Col>

          <Col className="d-flex justify-content-center">
            <Buttonaction
              borderblueOcean
              type="button"
              label="No"
              large
              onClick={() => modalConfirmRef.current.hide()}
            />
          </Col>
        </SubmitModal.ModalButtonActions>
      </SubmitModal.ModalContainer> */}

         {/* STANDALONE AUCTION REPORT MODAL */}
         <ModalForm
            passedInRef={modalDownloadAuctionReport}
            title={"Official Report"}
            modalTitle={"Price Disqualification"}
            closeButton={true}
         >
            <div className="mx-4 mb-4">
               <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
                  <Col className="px-3">Organizing Committee</Col>
                  <Col lg={9}>
                     {dataAuctionReport?.organizingCommittee ? (
                        <div className="d-flex">
                           {!editOrganizingCommittee && (
                              <>
                                 <div>
                                    <p style={{ marginRight: "10px" }}>{dataAuctionReport?.organizingCommittee}</p>
                                 </div>
                                 <Whisper placement="top" speaker={<Tooltips>Change organizing committee</Tooltips>}>
                                    <img
                                       src={PencilBlue}
                                       style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                       onClick={() => {
                                          setEditOrganizingCommittee(true)
                                       }}
                                    />
                                 </Whisper>
                              </>
                           )}

                           {editOrganizingCommittee && (
                              <>
                                 <div className="w-100 mr-2">
                                    <Form.Control
                                       placeholder="Divisi Pengelolaan Aset & Pengadaan"
                                       size="lg"
                                       value={payloadAuctionReport?.organizingCommittee}
                                       onChange={(e) =>
                                          setPayloadAuctionReport((prev) => ({
                                             ...prev,
                                             organizingCommittee: e.target.value,
                                          }))
                                       }
                                    />
                                 </div>
                                 <img
                                    src={CloseButtonSolidBlue}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setEditOrganizingCommittee(false),
                                          setPayloadAuctionReport((prev) => ({
                                             ...prev,
                                             organizingCommittee: dataAuctionReport.organizingCommittee,
                                          }))
                                    }}
                                 />
                              </>
                           )}
                        </div>
                     ) : (
                        <Form.Control
                           placeholder="Divisi Pengelolaan Aset & Pengadaan"
                           size="lg"
                           onChange={(e) =>
                              setPayloadAuctionReport((prev) => ({ ...prev, organizingCommittee: e.target.value }))
                           }
                        />
                     )}
                  </Col>
               </Row>
               <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
                  <Col className="px-3">Allocation</Col>
                  <Col lg={9}>
                     {dataAuctionReport?.allocation ? (
                        <div className="d-flex">
                           {!editAllocation && (
                              <>
                                 <div>
                                    <p style={{ marginRight: "10px" }}>{dataAuctionReport?.allocation}</p>
                                 </div>
                                 <Whisper placement="top" speaker={<Tooltips>Change allocation</Tooltips>}>
                                    <img
                                       src={PencilBlue}
                                       style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                       onClick={() => {
                                          setEditAllocation(true)
                                       }}
                                    />
                                 </Whisper>
                              </>
                           )}

                           {editAllocation && (
                              <>
                                 <div className="w-100 mr-2">
                                    <Form.Control
                                       placeholder="Pengelolaan Pengadaan Teknologi (PGT1)"
                                       size="lg"
                                       value={payloadAuctionReport?.allocation}
                                       onChange={(e) =>
                                          setPayloadAuctionReport((prev) => ({
                                             ...prev,
                                             allocation: e.target.value,
                                          }))
                                       }
                                    />
                                 </div>
                                 <img
                                    src={CloseButtonSolidBlue}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setEditAllocation(false),
                                          setPayloadAuctionReport((prev) => ({
                                             ...prev,
                                             allocation: dataAuctionReport.allocation,
                                          }))
                                    }}
                                 />
                              </>
                           )}
                        </div>
                     ) : (
                        <Form.Control
                           placeholder="Pengelolaan Pengadaan Teknologi (PGT1)"
                           size="lg"
                           onChange={(e) =>
                              setPayloadAuctionReport((prev) => ({ ...prev, allocation: e.target.value }))
                           }
                        />
                     )}
                  </Col>
               </Row>
               <Row className="d-flex align-items-center">
                  <Col className="px-3">Official Report</Col>
                  <Col lg={9}>
                     <Buttonaction
                        style={{ width: "100%" }}
                        blueOcean
                        disabled={!payloadAuctionReport.organizingCommittee && !payloadAuctionReport.allocation}
                        label="Generate & Download Report"
                        loadingLabel="Generating Report..."
                        loading={downloading}
                        onClick={() => {
                           if (payloadAuctionReport.organizingCommittee && payloadAuctionReport.allocation) {
                              handleGenerateReport(payloadAuctionReport)
                           } else {
                              toast.error("Please complete the input field")
                           }
                        }}
                     />
                  </Col>
               </Row>
               <Row className="d-flex align-items-center mt-1">
                  <Col className="px-3"></Col>
                  <Col lg={9}>
                     <span style={{ fontSize: 12, color: "#8F8F8F" }}>
                        This files include Official Report ( Berita Acara )
                     </span>
                  </Col>
               </Row>
            </div>
         </ModalForm>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      // dataProcurementAuctions: state.procurementReducer.dataProcurementAuctions,
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(InitiationAuctionList)
