import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { Checkbox } from "rsuite"
import Buttonaction from "../../../components/Buttonaction"
import { GreyCard } from "../../../components/styled/Generalcard"
import WarningBlue from "../../../assets/images/warning-blue.svg"
import { triggerBase64Download } from "common-base64-downloader-react"
import toast from "react-hot-toast"
import { getLogNegotiation, getLogAuction, getLogProcurement } from "../../../redux/action/reportAction"

import { Loader } from "rsuite"

import { getMyProfile } from "../../../redux/action/accountAction"

const LogReport = ({ passedInRef, handleExpand, totalDoneNegoBatches, totalDoneAuctions }) => {
   const { procurementId } = useParams()
   const dispatch = useDispatch()
   const [companyId, setCompanyId] = useState(null)

   let _values = []
   const [downloading, setDownloading] = useState(false)

   const handleClickDownload = () => {
      _values = Array.from(document.querySelectorAll('input[type="checkbox"]'))
         .filter((checkbox) => checkbox.checked)
         .map((checkbox) => checkbox.value)

      if (_values.length === 0) {
         toast.error("You need to checklist at least one checkbox!")
      } else {
         for (const value of _values) {
            if (value === "AUCTION") {
               new Promise((resolve, reject) => {
                  setDownloading(true)
                  dispatch(
                     getLogAuction(
                        procurementId,
                        companyId,
                        (resp) => {
                           resolve(`data:application/pdf;base64,${resp.data}`)
                        },
                        () => {
                           reject(new Error("Failed to download"))
                        },
                     ),
                  )
               })
                  .then((base64) => {
                     triggerBase64Download(base64, "Auction Log Report")
                  })
                  .catch((err) => {
                     console.log(err)
                  })
                  .finally(() => {
                     setDownloading(false)
                  })
            } else if (value === "NEGOTIATION") {
               new Promise((resolve, reject) => {
                  setDownloading(true)
                  dispatch(
                     getLogNegotiation(
                        procurementId,
                        companyId,
                        (resp) => {
                           resolve(`data:application/pdf;base64,${resp.data}`)
                        },
                        () => {
                           reject(new Error("Failed to download"))
                        },
                     ),
                  )
               })
                  .then((base64) => {
                     triggerBase64Download(base64, "Negotiation Log Report")
                  })
                  .catch((err) => {
                     console.log(err)
                  })
                  .finally(() => {
                     setDownloading(false)
                  })
            } else if (value === "PROCUREMENT") {
               new Promise((resolve, reject) => {
                  setDownloading(true)
                  dispatch(
                     getLogProcurement(
                        procurementId,
                        companyId,
                        (resp) => {
                           if (resp === null) {
                              toast.success("this procurement status still open", { duration: 3000 })
                              resolve()
                           } else {
                              resolve(`data:application/pdf;base64,${resp.data}`)
                           }
                        },
                        () => {
                           reject(new Error("Failed to download"))
                        },
                     ),
                  )
               })
                  .then((base64) => {
                     triggerBase64Download(base64, "Report Log Auction")
                  })
                  .catch((err) => {
                     console.log(err)
                  })
                  .finally(() => {
                     setDownloading(false)
                  })
            }
         }
      }
   }

   useEffect(() => {
      dispatch(
         getMyProfile((res) => {
            const companyId = res.account.companyId
            setCompanyId(companyId)
         }),
      )
   }, [])

   return (
      <div ref={passedInRef} id="log" onMouseEnter={() => handleExpand({ expand: "log" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard style={{ paddingBottom: "34px" }}>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Log Report
                        </p>
                        <Buttonaction
                           loading={downloading}
                           blueOcean
                           label="Generate & Download Session Log"
                           onClick={() => {
                              handleClickDownload()
                           }}
                        />
                     </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />
                  <Info>
                     <img src={WarningBlue} style={{ position: "absolute", top: "0", left: "0" }} />
                     <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-5">
                        Only session that is done can be downloaded.
                     </p>
                  </Info>

                  {totalDoneAuctions > 0 && (
                     <div className="d-flex align-items-center">
                        {" "}
                        <Checkbox type="checkbox" name="type" value="AUCTION" />
                        <span
                           className="ml-4"
                           style={{
                              fontSize: "14px",
                              color: "#454545",
                              fontWeight: "500",
                           }}
                        >
                           Auction Log Report
                        </span>
                     </div>
                  )}

                  {/* {totalDoneNegoBatches > 0 && (
                     <div className="d-flex align-items-center">
                        {" "}
                        <Checkbox type="checkbox" name="type" value="NEGOTIATION" />
                        <span
                           className="ml-4"
                           style={{
                              fontSize: "14px",
                              color: "#454545",
                              fontWeight: "500",
                           }}
                        >
                           Negotiation Result Auction
                        </span>
                     </div>
                  )} */}
                  {/* <div className="d-flex align-items-center">
              {" "}
              <Checkbox type="checkbox" name="type" value="PROCUREMENT" />
              <span
                className="ml-4"
                style={{
                  fontSize: "14px",
                  color: "#454545",
                  fontWeight: "500",
                }}
              >
                Report Result Auction
              </span>
            </div> */}
               </GreyCard>
            </Col>
         </Row>
      </div>
   )
}

const mapStateToProps = (state) => ({
   dataLogNegotiation: state.reportReducer.dataLogNegotiation,
   dataLogAuction: state.reportReducer.dataLogAuction,
   // dataProfile: state.accountReducer.dataProfile,
})

export default connect(mapStateToProps)(LogReport)

const Info = styled.div`
   background-color: #ecf5fb;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
   margin-left: 10px;
   margin-right: 10px;
`
