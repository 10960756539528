import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import NegotiationService from "../../../services/NegotiationService"
import NegotiationBatchService from "../../../services/NegotiationbatchService"
import { getProcurement, getProcurementPublic } from "../../../redux/action/procurementAction"
import { GreyCard } from "../../../components/styled/Generalcard"
import { Row, Col } from "react-bootstrap"
import Dateinfo from "../../../components/Dateinfo"
import Constant from "../../../../constant"

const NegotiationInformation = (params) => {
   const { negotiationId, negotiationBatchId, account } = params
   const dispatch = useDispatch()
   const [negotiationBatch, setNegotiationBatch] = useState({})
   const [negotiation, setNegotiation] = useState({})
   const [dataProcurement, setDataProcurement] = useState({})
   const negotiationService = new NegotiationService()
   const negotiationBatchService = new NegotiationBatchService()

   useEffect(() => {
      getNegotiationBatchDetail(negotiationBatchId)
      getNegotiationDetail(negotiationId)
   }, [])

   const getNegotiationBatchDetail = () => {
      dispatch(
         negotiationBatchService.findById(negotiationBatchId, (res) => {
            setNegotiationBatch(res)
            if (account && account.role !== Constant.ROLE.BUYER_AUCTION) {
               dispatch(
                  getProcurementPublic({ procurementId: res.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            } else {
               dispatch(
                  getProcurement({ procurementId: res.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            }
         }),
      )
   }
   const getNegotiationDetail = () => {
      dispatch(
         negotiationService.findById(negotiationId, (res) => {
            setNegotiation(res)
         }),
      )
   }

   return (
      <>
         <div>
            <GreyCard className="w-100 ml-0" style={{ paddingLeft: "30px" }}>
               <Row>
                  <Col style={{ fontSize: "14px", fontWeight: "500" }}>{dataProcurement?.procurementName}</Col>
                  <Col style={{ fontSize: "14px", fontWeight: "400" }}>
                     <span>Start Time</span>
                     <Dateinfo
                        style={{ all: "unset", marginLeft: "24px" }}
                        date={negotiation?.startDate}
                        format="dd mmmm yyyy hh:mm:ss"
                     />
                  </Col>
               </Row>
               <Row>
                  <Col className="mt-1" style={{ fontSize: "12px", fontWeight: "400" }}>
                     Negotiation Room [ {negotiationBatch?.name} ]
                  </Col>
                  <Col style={{ fontSize: "14px", fontWeight: "500", marginTop: "2px" }}>
                     Vendor <span style={{ marginLeft: "40px" }}>{negotiation?.vendorName}</span>
                  </Col>
               </Row>
            </GreyCard>
         </div>
      </>
   )
}

export default NegotiationInformation
