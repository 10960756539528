import React, { useEffect, useRef, useState } from "react"
import { Nav } from "rsuite"
import styled from "styled-components"
import { orderBy as _orderBy } from "lodash"
import { useParams } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

// Components
import ByVendor from "./tabs/ByVendor"
import ByItem from "./tabs/ByItem"
import AddItemVendor from "./modals/AddItems"
import VendorsPerItem from "./modals/VendorsPerItem"
import ModalForm from "../../../components/Modalform"
import { Editlink } from "../../../components/Editlink"
import ScoringTechnicalform from "./ScoringTechnicalform"
import Statuslabel from "../../../components/Statuslabel"
import Buttonaction from "../../../components/Buttonaction"
import MuiDataTable from "../../../components/MuiDataTable"
import { GreyCard } from "../../../components/styled/Generalcard"
import { AnnouncementInfo } from "../../../components/Announcement"
import ScoringAdministrationform from "./ScoringAdministrationform"
import InitiationMeritScoringform from "./InitiationMeritScoringform"

// Assets
import Trophy from "../../../assets/images/Icon-Trophy.svg"
import WritingDocumentIcon from "../../../assets/images/WritingDocument.svg"

// Redux
import {
   fetchProcurementVendors,
   fetchProcurementVendorsWithoutPagination,
   fetchProcurementItems,
   getProcurement,
   setProcurementVendorsState,
} from "../../../redux/action/procurementAction"
import { getVendorItems } from "../../../redux/action/auctionAction"

// #
import Constant from "../../../../constant"
import Formservice from "../../../services/Formservice"
import ProcurementVendorUtil from "../../../services/utils/ProcurementVendorUtil"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"
import { fetchMasterData } from "../../../redux/action/masterDataAction"

function InitiationMeritScoringlist({
   dataProcurement,
   dataProcurementVendors,
   isAppLoading,
   handleGetVendorRanking,
   dataMasters,
}) {
   const { procurementId } = useParams()

   // Component state
   const [isInitial, setIsInitial] = useState(false)
   const [finalOffer, setFinalOffer] = useState(null)
   const [winnerVendor, setWinnerVendor] = useState([])
   const [dataAuctionPerVendor, setDataAuctionPerVendor] = useState({})
   const [vendorUnitPriceHistory, setVendorUnitPriceHistory] = useState([])
   const [dataProcurementItems, setDataProcurementItems] = useState({ filteredData: [], total: null })
   const [_dataProcurementVendors, setDataProcurementVendors] = useState({
      filteredData: [],
      total: 0,
   })
   const dispatch = useDispatch()
   const [dataState, setDataState] = useState({
      procurementId,
      page: 0,
      limit: 5,
      query: "",
      selectedRows: [],
   })

   // Component Refs
   const modalItemFormRef = useRef()
   const modalAddItemVendorRef = useRef()
   const modalVendorsPerItemRef = useRef()
   const vendorAuctionHistoryRef = useRef()
   const modalScoringTechnicalRef = useRef()
   const modalScoringAdministrationRef = useRef()

   /**
    * Table handling
    * ----------------
    */
   const topColumns = ["Administration Percentage", "Technical Percentage", "Price Percentage", "Total Percentage"]
   const historyColumn = ["Item Code", "Item Name", "Qty", "UOM", "Unit Price", "Subtotal"]

   // Table state
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")

   const handleRequestSort = (column) => {
      alert(column)
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Company Name"
            ? "name"
            : column === "Administration"
            ? "administrationScore"
            : column === "Technical"
            ? "technicalScore"
            : column === "Price"
            ? "priceScore"
            : column === "Total"
            ? "totalScore"
            : column === "Indicator"
            ? "indicatorPrice"
            : column === "Last"
            ? "lastPrice"
            : column === "Saving"
            ? "saving"
            : ""

      let result = []
      result = _orderBy(_dataProcurementVendors.filteredData, [forColumn], [_order])

      setDataProcurementVendors({
         ..._dataProcurementVendors,
         filteredData: result,
      })
      // dispatch(setProcurementVendorsState(result));
   }

   const handleRequestSortHistory = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "item.itemCode"
            : column === "Item Name"
            ? "item.itemName"
            : column === "Qty"
            ? "item.quantity"
            : column === "UOM"
            ? "item.uom"
            : column === "Unit Price"
            ? "lastPrice"
            : column === "Subtotal"
            ? "item.subTotal"
            : ""

      let result = []
      result = _orderBy(vendorUnitPriceHistory, [forColumn], [_order])

      setVendorUnitPriceHistory(result)
   }

   const search = () => {
      dispatch(
         fetchProcurementVendors({ ...dataState, procurementId, limit: 100000 }, (res) => {
            if (res.totalProcurementVendors === 0) {
               setEmptySearchResult(true),
                  setDataProcurementVendors({
                     ..._dataProcurementVendors,
                     filteredData: [],
                     total: 0,
                  })
            } else {
               setDataProcurementVendors({
                  ...res,
                  filteredData: res.filteredData.sort((a, b) => {
                     return b.totalScore - a.totalScore
                  }),
               })
            }
         }),
      )
   }

   // const changePage = async (page) => {
   //    await dispatch(
   //       fetchProcurementVendorsWithoutPagination({
   //          procurementId,
   //          page: page,
   //          limit: dataState.limit,
   //          query: dataState.query,
   //       }),
   //    )
   //    setDataState((state) => ({
   //       ...state,
   //       page,
   //    }))
   // }

   useEffect(() => {
      const language = window.localStorage.getItem("lang")
      dispatch(
         fetchMasterData(language, (dataMasters) => {
            dispatch(
               getProcurement(
                  { procurementId },
                  (res) => {},
                  (err) => {},
                  dataMasters,
               ),
            )
         }),
      )

      dispatch(
         fetchProcurementVendorsWithoutPagination({
            procurementId,
            page: 1,
            limit: dataState.limit,
         }),
      )

      dispatch(
         fetchProcurementVendorsWithoutPagination({ procurementId }, (res) => {
            const priceExist = res.data.filter((vendor) => vendor.lastPrice > 0).length > 0

            if (!priceExist) {
               setIsInitial(true)
               setDataProcurementVendors({
                  ..._dataProcurementVendors,
                  filteredData: res.data,
                  total: res.totalProcurementVendors,
               })
               handleGetVendorRanking(res.data)
            } else {
               const sortByWinner = ProcurementVendorUtil.sortEvaluationScore(dataProcurement, res.data)

               let vendorRanking = []
               sortByWinner.map((vendor, index) => vendorRanking.push({ ...vendor, ranking: index + 1 }))

               handleGetVendorRanking(vendorRanking)
               setWinnerVendor(vendorRanking)
               setDataProcurementVendors({
                  ..._dataProcurementVendors,
                  filteredData: sortByWinner,
                  total: res.totalProcurementVendors,
               })
            }
         }),
      )
   }, [])

   useEffect(() => {
      if (vendorUnitPriceHistory.length > 0) {
         let totalOffer = 0
         for (let index = 0; index < vendorUnitPriceHistory.length; index++) {
            const element = vendorUnitPriceHistory[index]

            totalOffer = totalOffer + element.item.quantity * element.lastPrice
         }
         setFinalOffer(totalOffer)
      }
   }, [vendorUnitPriceHistory])

   const evaluationScoreRanking = (data) => {
      return data.sort((a, b) => b.totalScore - a.totalScore)
   }

   /**
    * Tab handling
    */
   const tabs = ["By Item", "By Vendor"]

   // Tab state
   const [activeTab, setActiveTab] = useState("By Item")

   // Variables
   const isTotalPriceByUnit = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT

   return (
      <>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Evaluation Score
                        </p>
                        {!dataProcurement?.evaluationScoring && (
                           <Buttonaction
                              blueOcean
                              label="Scoring Percentage"
                              faIcon={faPlusCircle}
                              onClick={() => modalItemFormRef.current.show()}
                           />
                        )}
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  {(!dataProcurement?.evaluationScoring && (
                     <AnnouncementInfo
                        text="Define the Administration, Technical, and Price Scale to weight your score later"
                        icon={WritingDocumentIcon}
                        textUnderIcon={
                           <>
                              No list available,
                              <br />
                              Let’s try to add one!
                           </>
                        }
                     />
                  )) || (
                     <>
                        <MuiDataTable.CustomTable>
                           <TableHead>
                              <TableRow>
                                 {topColumns.map((title, index) => (
                                    <TableCell key={index} align="center" className="py-2">
                                       {title}
                                    </TableCell>
                                 ))}
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              <TableRow
                                 sx={{
                                    backgroundColor: "#F3F5F4",
                                 }}
                              >
                                 <TableCell align="center" className="py-2">
                                    {dataProcurement.evaluationScoring.administrationValue + " %"}
                                 </TableCell>
                                 <TableCell align="center" className="py-2">
                                    {dataProcurement.evaluationScoring?.technicalValue + " %"}
                                 </TableCell>
                                 <TableCell align="center" className="py-2">
                                    {dataProcurement.evaluationScoring?.priceValue + " %"}
                                 </TableCell>
                                 <TableCell align="center" className="py-2">
                                    {dataProcurement.evaluationScoring?.technicalValue +
                                       dataProcurement.evaluationScoring?.administrationValue +
                                       dataProcurement.evaluationScoring?.priceValue +
                                       " %"}
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </MuiDataTable.CustomTable>

                        <hr
                           style={{
                              background: "#C4C4C4",
                           }}
                        />

                        {dataProcurement.biddingType == "UNIT_PRICE" && (
                           <>
                              <Nav appearance="tabs" activeKey={activeTab} onSelect={setActiveTab}>
                                 {tabs.map((tab, index) => (
                                    <Nav.Item
                                       key={index}
                                       eventKey={tab}
                                       style={{
                                          width: "141px",
                                          fontWeight: "500",
                                          textAlign: "center",
                                          color: activeTab != tab && "#FFFFFF",
                                          background: activeTab != tab && "#0772B6",
                                          marginRight: "2px",
                                       }}
                                    >
                                       {tab}
                                    </Nav.Item>
                                 ))}
                              </Nav>

                              <TabContentWrapper>
                                 {activeTab == "By Item" && (
                                    <div>
                                       <ByItem modalVendorsPerItemRef={modalVendorsPerItemRef} />
                                    </div>
                                 )}

                                 {activeTab == "By Vendor" && (
                                    <div>
                                       <ByVendor
                                          modalScoringAdministrationRef={modalScoringAdministrationRef}
                                          modalAddItemVendorRef={modalAddItemVendorRef}
                                       />
                                    </div>
                                 )}
                                 {/* {activeTab == "By Vendor" && dataProcurement.status == Constant.procurementStatus.OPEN && (
                        <div></div>
                     )} */}
                              </TabContentWrapper>
                           </>
                        )}

                        {dataProcurement?.biddingType !== "UNIT_PRICE" && dataProcurement.status !== "DRAFT" ? (
                           <MuiDataTable.CustomTable
                              showHeader={true}
                              headerComponent={
                                 <MuiDataTable.Filter
                                    withSearchBar
                                    handleSearch={(v) =>
                                       setDataState({
                                          ...dataState,
                                          query: v.target.value,
                                       })
                                    }
                                    onKeyUp={(v) => v.keyCode === 13 && search(v)}
                                 />
                              }
                              // footerComponent={
                              //   <TablePagination
                              //     sx={{ background: "#ffffff", borderBottom: "none" }}
                              //     page={dataState.page}
                              //     count={dataProcurementVendors.total}
                              //     rowsPerPage={dataState.limit}
                              //     rowsPerPageOptions={[
                              //       1,
                              //       2,
                              //       5,
                              //       10,
                              //       15,
                              //       20,
                              //       { label: "All", value: -1 },
                              //     ]}
                              //     onPageChange={
                              //       (v) => console.log(v)
                              //       // setDataState({ ...dataState, page: dataState.page + 1 })
                              //     }
                              //     on
                              //     onRowsPerPageChange={(v) =>
                              //       setDataState({ ...dataState, limit: v.target.value })
                              //     }
                              //     // ActionsComponent={TablePaginationActions}
                              //     className="d-flex justify-content-end"
                              //     // sx={{ width: "100%", display: "flex" }}
                              //   />
                              // }
                           >
                              <TableHead>
                                 <TableRow>
                                    <TableCell
                                       rowSpan={2}
                                       sx={{
                                          overflow: "auto",
                                          whiteSpace: "nowrap",
                                          borderRight: "1px solid #ffffff",
                                       }}
                                    >
                                       <TableSortLabel
                                          active={orderBy === "Company Name"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Company Name
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                       colSpan={4}
                                       align="center"
                                       sx={{
                                          borderBottom: "1px solid #ffffff",
                                       }}
                                       className="py-2"
                                    >
                                       Score
                                    </TableCell>
                                    <TableCell
                                       colSpan={2}
                                       align="center"
                                       sx={{
                                          border: "1px solid #ffffff",
                                       }}
                                       className="py-2"
                                    >
                                       Price
                                    </TableCell>
                                    {/* <TableCell
                                       rowSpan={2}
                                       sx={{
                                          borderLeft: "1px solid #ffffff",
                                       }}
                                    >
                                       <TableSortLabel
                                          active={orderBy === "Saving"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Saving
                                       </TableSortLabel>
                                    </TableCell> */}
                                    <TableCell
                                       rowSpan={2}
                                       sx={{
                                          borderLeft: "none",
                                       }}
                                    >
                                       Rank
                                    </TableCell>
                                 </TableRow>
                                 <TableRow>
                                    <TableCell style={{ paddingRight: 0 }} className="py-2">
                                       <TableSortLabel
                                          active={orderBy === "Administration"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Administration
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right" style={{ paddingLeft: 0 }} className="py-2">
                                       <TableSortLabel
                                          active={orderBy === "Technical"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Technical
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right" className="py-2">
                                       <TableSortLabel
                                          active={orderBy === "Price"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Price
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right" className="py-2">
                                       <TableSortLabel
                                          active={orderBy === "Total"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Total
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                       align="right"
                                       sx={{
                                          borderLeft: "1px solid #ffffff",
                                       }}
                                       className="py-2 w-100"
                                    >
                                       <TableSortLabel
                                          active={orderBy === "Indicator"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                       >
                                          Indicator
                                       </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                       align="right"
                                       className="py-2 w-100"
                                       sx={{
                                          borderRight: "1px solid #ffffff",
                                       }}
                                    >
                                       <TableSortLabel
                                          active={orderBy === "Last"}
                                          direction={order}
                                          onClick={(v) => handleRequestSort(v.target.outerText)}
                                          IconComponent={ExpandLessIcon}
                                          sx={{ whiteSpace: "nowrap" }}
                                       >
                                          Last Price
                                       </TableSortLabel>
                                    </TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {_dataProcurementVendors?.filteredData.length > 0 ? (
                                    _dataProcurementVendors?.filteredData.map((vendor, index) => (
                                       <TableRow
                                          key={index}
                                          sx={{
                                             background: "#F3F5F4",
                                             "&:hover": {
                                                background: "transparent",
                                             },
                                          }}
                                       >
                                          <TableCell
                                             sx={{
                                                fontWeight: "500",
                                                whiteSpace: "nowrap",
                                                minWidth: "200px",
                                                color:
                                                   dataProcurement?.biddingType ===
                                                      Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                                   "#454545",
                                                textDecoration:
                                                   dataProcurement?.biddingType ===
                                                      Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                                   "underline",
                                                cursor:
                                                   dataProcurement?.biddingType ===
                                                      Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                                   "pointer",
                                             }}
                                             onClick={() => {
                                                const vendorId = vendor._id
                                                dataProcurement?.biddingType ===
                                                   Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT &&
                                                   vendorAuctionHistoryRef.current.show()
                                                dispatch(
                                                   getVendorItems(
                                                      {
                                                         vendorId,
                                                      },
                                                      (res) => {
                                                         const newRes = []
                                                         for (const data of res) {
                                                            newRes.push({
                                                               ...data,
                                                               item: {
                                                                  ...data.item,
                                                                  subTotal: data.item.quantity * data.lastPrice,
                                                               },
                                                            })
                                                         }
                                                         setVendorUnitPriceHistory(newRes)
                                                      },
                                                   ),
                                                )
                                                const dataVendor = _dataProcurementVendors?.filteredData.filter(
                                                   (data) => data._id == vendor._id,
                                                )
                                                setDataAuctionPerVendor(dataVendor[0])
                                             }}
                                             className="py-2"
                                          >
                                             {vendor.name}
                                          </TableCell>
                                          <TableCell width={10} className="py-2">
                                             {vendor.administrationPassed ? (
                                                <Statuslabel label={"Passed"} status={"PASSED"} className="mx-0" />
                                             ) : (
                                                <Statuslabel
                                                   label={"Not Passed"}
                                                   status={"NOT_PASSED"}
                                                   className="mx-0"
                                                />
                                             )}
                                          </TableCell>
                                          <TableCell align="right" className="py-2">
                                             <span>{vendor.technicalScore?.toFixed(2)}</span>
                                          </TableCell>
                                          <TableCell align="right" className="py-2">
                                             {vendor.priceScore?.toFixed(2)}
                                          </TableCell>
                                          <TableCell align="right" className="py-2">
                                             {vendor.totalScore?.toFixed(2)}
                                          </TableCell>
                                          <TableCell
                                             sx={{
                                                whiteSpace: "nowrap",
                                             }}
                                             align="right"
                                             className="py-2"
                                          >
                                             {Formservice.CurrencyFormatter(vendor.indicatorPrice, {
                                                currency: dataProcurement?.currency,
                                             })}
                                          </TableCell>
                                          <TableCell
                                             sx={{
                                                whiteSpace: "nowrap",
                                             }}
                                             align="right"
                                             className="py-2"
                                          >
                                             {Formservice.CurrencyFormatter(vendor.lastPrice, {
                                                currency: dataProcurement?.currency,
                                             })}
                                          </TableCell>
                                          {/* <TableCell
                                             sx={{
                                                whiteSpace: "nowrap",
                                                fontWeight: "600",
                                             }}
                                             className="py-2"
                                          >
                                             {isInitial ? (
                                                <span>-</span>
                                             ) : (
                                                <div
                                                   style={{
                                                      color:
                                                         vendor.saving > 0
                                                            ? "#4DAF51"
                                                            : vendor.saving < 0
                                                            ? "#F44437"
                                                            : "grey",
                                                   }}
                                                >
                                                   {vendor.saving.toFixed(2) + " %"}
                                                </div>
                                             )}
                                          </TableCell> */}
                                          <TableCell
                                             sx={{
                                                whiteSpace: "nowrap",
                                                fontWeight: "600",
                                             }}
                                             className="py-2"
                                          >
                                             {isInitial ? (
                                                <span style={{ fontWeight: 700 }}>-</span>
                                             ) : (
                                                <div>
                                                   {winnerVendor.findIndex((_vendor) => _vendor._id === vendor._id) ===
                                                   0 ? (
                                                      <>
                                                         {vendor.saving >= 0 && vendor.lastPrice > 0 ? (
                                                            <span
                                                               style={{
                                                                  fontWeight: 700,
                                                               }}
                                                            >
                                                               1
                                                               <img
                                                                  src={Trophy}
                                                                  style={{
                                                                     marginLeft: "10px",
                                                                  }}
                                                               />
                                                            </span>
                                                         ) : vendor.indicatorPrice < 1 ? (
                                                            <span>-</span>
                                                         ) : vendor.saving < 0 ? (
                                                            <span>{winnerVendor.length}</span>
                                                         ) : (
                                                            <span>1</span>
                                                         )}
                                                      </>
                                                   ) : (
                                                      <span
                                                         style={{
                                                            fontWeight: 700,
                                                         }}
                                                      >
                                                         {vendor.saving < 0
                                                            ? winnerVendor.length
                                                            : vendor.indicatorPrice < 1
                                                            ? "-"
                                                            : winnerVendor.findIndex(
                                                                 (_vendor) => _vendor._id === vendor._id,
                                                              ) + 1}
                                                      </span>
                                                   )}
                                                </div>
                                             )}
                                          </TableCell>
                                       </TableRow>
                                    ))
                                 ) : (
                                    <TableRow
                                       sx={{
                                          background: "#F3F5F4",
                                       }}
                                    >
                                       <TableCell colSpan={9} align="center" sx={{ fontWeight: 500 }}>
                                          No records to display
                                       </TableCell>
                                    </TableRow>
                                 )}
                              </TableBody>
                           </MuiDataTable.CustomTable>
                        ) : dataProcurement?.biddingType !== "UNIT_PRICE" ? (
                           <MuiDataTable.CustomTable>
                              <TableHead>
                                 <TableRow>
                                    <TableCell className="py-2">Company Name</TableCell>
                                    <TableCell
                                       className="py-2"
                                       sx={{
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       Administration Score
                                    </TableCell>
                                    <TableCell className="py-2" align="right">
                                       Technical Score
                                    </TableCell>
                                    <TableCell className="py-2" align="right">
                                       Total Score
                                    </TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {dataProcurementVendors.filteredData.map((vendor, index) => (
                                    <TableRow
                                       key={index}
                                       sx={{
                                          background: "#F3F5F4",
                                       }}
                                    >
                                       <TableCell
                                          className="py-2"
                                          sx={{
                                             fontWeight: "500",
                                             whiteSpace: "nowrap",
                                             minWidth: "200px",
                                          }}
                                       >
                                          {vendor.name}
                                       </TableCell>
                                       <TableCell className="py-2" width={10}>
                                          {vendor.administrationPassed == null ? (
                                             <Editlink
                                                style={{ fontWeight: "500" }}
                                                onClick={() =>
                                                   modalScoringAdministrationRef.current.show({
                                                      vendorName: vendor.name,
                                                      procurementNumber: dataProcurement?.procurementNumber,
                                                      procurementVendorId: vendor._id,
                                                   })
                                                }
                                             >
                                                Give Score
                                             </Editlink>
                                          ) : vendor.administrationPassed ? (
                                             <Statuslabel label={"Passed"} status={"PASSED"} className="mx-0" />
                                          ) : (
                                             <Statuslabel label={"Not Passed"} status={"NOT_PASSED"} className="mx-0" />
                                          )}
                                       </TableCell>
                                       <TableCell className="py-2" align="right">
                                          {(!vendor.technicalScore && (
                                             <Editlink
                                                onClick={() =>
                                                   modalScoringTechnicalRef.current.show({
                                                      vendorName: vendor.name,
                                                      procurementNumber: dataProcurement?.procurementNumber,
                                                      procurementVendorId: vendor._id,
                                                   })
                                                }
                                             >
                                                Give Score
                                             </Editlink>
                                          )) || <span>{vendor.technicalScore?.toFixed(2)}</span>}
                                       </TableCell>
                                       <TableCell className="py-2" align="right">
                                          {vendor.totalScore?.toFixed(2)}
                                       </TableCell>
                                    </TableRow>
                                 ))}
                              </TableBody>
                           </MuiDataTable.CustomTable>
                        ) : (
                           <></>
                        )}
                     </>
                  )}
               </GreyCard>
            </Col>
         </Row>

         <InitiationMeritScoringform passedInRef={modalItemFormRef} />
         <ScoringAdministrationform passedInRef={modalScoringAdministrationRef} />
         <ScoringTechnicalform passedInRef={modalScoringTechnicalRef} />

         <VendorsPerItem passedInRef={modalVendorsPerItemRef} dataProcurement={dataProcurement} />
         <AddItemVendor passedInRef={modalAddItemVendorRef} />

         <ModalForm passedInRef={vendorAuctionHistoryRef} title="Unit Price" closeButton={true}>
            {" "}
            <div
               style={{
                  padding: "0 26px 42px",
                  overflow: "auto",
                  height: "50vh",
               }}
            >
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Company
                  </Col>
                  <Col>{dataAuctionPerVendor && dataAuctionPerVendor.name}</Col>
               </Row>
               <Row className="mb-3">
                  <Col lg={3} className="px-3">
                     Indicator Price
                  </Col>
                  <Col>
                     {dataAuctionPerVendor &&
                        Formservice.CurrencyFormatter(dataAuctionPerVendor.indicatorPrice, {
                           currency: dataProcurement?.currency,
                        })}
                  </Col>
               </Row>
               <Row className="mb-4">
                  <Col lg={3} className="px-3">
                     Last Price
                  </Col>
                  <Col>
                     {dataAuctionPerVendor &&
                        Formservice.CurrencyFormatter(dataAuctionPerVendor.lastPrice, {
                           currency: dataProcurement?.currency,
                        })}
                  </Col>
               </Row>
               <MuiDataTable.CustomTable>
                  <TableHead>
                     <TableRow>
                        {historyColumn.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{ whiteSpace: "nowrap" }}
                              align={index === 2 || index === 4 || index === 5 ? "right" : "left"}
                           >
                              <TableSortLabel
                                 active={orderBy === column}
                                 direction={order}
                                 IconComponent={ExpandLessIcon}
                                 onClick={() => handleRequestSortHistory(column)}
                              >
                                 {column}
                              </TableSortLabel>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {vendorUnitPriceHistory &&
                        vendorUnitPriceHistory.map((v, index) => (
                           <TableRow key={index} sx={{ background: "#F3F5F4" }}>
                              <TableCell className="py-2">{v.item.itemCode}</TableCell>
                              <TableCell className="py-2">{v.item.itemName}</TableCell>
                              <TableCell className="py-2" align="right">
                                 {v.item.quantity}
                              </TableCell>
                              <TableCell className="py-2">{v.item.uom}</TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.lastPrice, { currency: dataProcurement?.currency })}
                              </TableCell>
                              <TableCell className="py-2" sx={{ whiteSpace: "nowrap" }} align="right">
                                 {Formservice.CurrencyFormatter(v.item.subTotal, {
                                    currency: dataProcurement?.currency,
                                 })}
                              </TableCell>
                           </TableRow>
                        ))}
                     <TableRow sx={{ background: "#F3F5F4" }}>
                        <TableCell
                           colSpan={6}
                           align="right"
                           sx={{
                              fontWeight: 500,
                              whiteSpace: "nowrap",
                           }}
                           className="py-2"
                        >
                           <span className="mr-5">Total Offer</span>
                           {Formservice.CurrencyFormatter(finalOffer, { currency: dataProcurement?.currency })}
                        </TableCell>
                     </TableRow>
                  </TableBody>
               </MuiDataTable.CustomTable>
            </div>{" "}
         </ModalForm>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
      dataProcurement: state.procurementReducer.dataProcurement,
      isAppLoading: state.appReducer.isAppLoading,
      dataMasters: state.masterDataReducer.dataMasters,
   }
}

export default connect(mapStateToProps)(InitiationMeritScoringlist)

const TabContentWrapper = styled.div`
   position: relative;
   border-radius: 0 0 5px 5px;
   border-left: 1px solid #d9d9d9;
   border-right: 1px solid #d9d9d9;
   border-bottom: 1px solid #d9d9d9;
   margin-bottom: 32px;
   padding: 4px 18px 18px;
   /* &::after {
      content: "";
      height: 4px;
      width: 4px;
      background: red;
      position: absolute;
      right: -1px;
      top: -1px; 
   } */
`
