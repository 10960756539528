import React, { useEffect, useRef, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Message, toaster } from "rsuite"
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { useParams, useHistory } from "react-router-dom"
import { fetchAllVendorScores, setNegotiationSchedulePerVendor } from "../../../../../../redux/action/negotiationAction"
import Buttonaction from "../../../../../../components/Buttonaction"
import Dateinfo from "../../../../../../components/Dateinfo"
import CalendarWhiteIcon from "../../../../../../assets/images/CalendarWhiteIcon.svg"
import {
   fetchProcurementVendorsWithoutPagination,
   getProcurement,
} from "../../../../../../redux/action/procurementAction"
import DatePickerUtil from "../../../../../../services/utils/DatePickerUtil"

import ScheduleModal from "../../../../../../modules/initiation/negotiation/NegotiationListTable/modals/SetSchedule"
import NegoUnitPriceModal from "../../../../../../modules/initiation/negotiation/NegotiationListTable/modals/NegoUnitPrice"
import { GreyCard } from "../../../../../../components/styled/Generalcard"

// Mui Library
import MuiDataTable from "../../../../../../components/MuiDataTable"
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
// import BuyerNegotiationOffline from "../../../../../../modules/initiation/negotiation/buyer/buyer-nego-offline.module"
import Constant from "../../../../../../../constant"
import NegotiationService from "../../../../../../services/NegotiationService"
import Statuslabel from "../../../../../../components/Statuslabel"
import toast from "react-hot-toast"
import NegotiationBatchService from "../../../../../../services/NegotiationbatchService"
// ================

import NegotiationOffer from "../../negotiation/negotiation-offline/NegotiationOffer"
import { format } from "date-fns"

const NegotiaionSetSchedule = ({ dataProcurement, currentNegoStatusAndData, modalNegotiationVendorRef }) => {
   const { batchId, procurementId } = useParams()
   const history = useHistory()
   const dispatch = useDispatch()
   const negotiationService = new NegotiationService()
   const negotiationBatchService = new NegotiationBatchService()

   const [showOffer, setShowOffer] = useState(false)
   const modalRef = useRef()
   const negoUnitPriceRef = useRef()
   const [dataProcurementVendors, setDataProcurementVendors] = useState([])
   const [procurementVendorData, setProcurementVendorData] = useState({
      startDate: null,
      endDate: null,
   })
   // const [negotiation, setNegotiation] = useState()
   const [negotiationBatch, setNegotiationBatch] = useState()
   const [selectNegotiation, setSelectNegotiation] = useState()
   const [negotiations, setNegotiations] = useState([])
   const [vendorItems, setVendorItems] = useState([])
   const [vendorScores, setVendorScores] = useState([])
   const [isProcessing, setIsProcessing] = useState(false)

   const negotiationOfferRef = useRef()

   const fetchVendorScores = () => {
      dispatch(
         fetchAllVendorScores(batchId, (res) => {
            if (dataProcurement?.evaluationMethod !== Constant.EVALUATION_METHODS.MERIT_POINT) {
               const sortedByWinner = res.sort((a, b) => b.saving - a.saving)
               setVendorScores(sortedByWinner)
            } else {
               setVendorScores(res)
            }
            setNegotiations(res)
         }),
      )
   }

   const handleGetNegotiationByBatchId = (batchId) => {
      dispatch(
         negotiationService.findByBatchId(batchId, (res) => {
            setNegotiations(res)
         }),
      )
   }

   const handleGetNegotiationBatchById = (batchId) => {
      dispatch(
         negotiationBatchService.findById(batchId, (res) => {
            setNegotiationBatch(res)
         }),
      )
   }

   useEffect(() => {
      if (batchId) {
         handleGetNegotiationByBatchId(batchId)
         handleGetNegotiationBatchById(batchId)
      }
   }, [])

   useEffect(() => {
      dispatch(
         getProcurement({ procurementId }, (res) => {
            const procurementId = res._id
            dispatch(
               fetchProcurementVendorsWithoutPagination({ procurementId }, (res) => {
                  setDataProcurementVendors(res.data)
               }),
            )
         }),
      )
   }, [])

   const handleOpenModal = (negotiation) => {
      setSelectNegotiation(negotiation)
      modalRef.current.show()
   }

   const handleSubmitInvitation = async (negotiation, callback, errCallback) => {
      if (negotiation.startDate) {
         const procurementEndDate = new Date(dataProcurement.endDate).getTime()
         const negotiationBatchStartDate = new Date(currentNegoStatusAndData.startDate).getTime()
         const inputStartDate = new Date(
            new Date(negotiation.startDate).getFullYear(),
            new Date(negotiation.startDate).getMonth(),
            new Date(negotiation.startDate).getDate(),
            new Date(negotiation.startDate).getHours(),
            new Date(negotiation.startDate).getMinutes(),
            0, // set input second to 0
         ).getTime()

         const startDateFormat = format(new Date(currentNegoStatusAndData.startDate), "dd MMMM yyyy kk:mm")
         
         if (dataProcurement.startDate && dataProcurement.endDate) {
            const endDateFormat = format(new Date(dataProcurement.endDate), "dd MMMM yyyy kk:mm")
            if (inputStartDate < negotiationBatchStartDate || inputStartDate > procurementEndDate) {
               toast.error(
                  <p>
                     Please input <strong>start date</strong> between <strong>{startDateFormat}</strong> and{" "}
                     <strong>{endDateFormat}</strong>
                  </p>,
                  { id: "negoSessionStart", duration: 5000 },
               )
               return
            }
         } else {
            if (inputStartDate < negotiationBatchStartDate) {
               toast.error(
                  <p>
                     Please input <strong>start date</strong> above <strong>{startDateFormat}</strong>
                  </p>,
                  { id: "negoSessionStart", duration: 5000 },
               )
               return
            }
         }

         setIsProcessing(true)
         return new Promise((resolve, reject) => {
            dispatch(
               negotiationService.submitSchedule(
                  negotiation,
                  (res) => {
                     resolve(res)
                  },
                  (err) => {
                     reject(err)
                  },
               ),
            )
         })
            .then((res) => {
               modalRef.current.hide()
               handleGetNegotiationByBatchId(batchId)
               toaster.push(
                  <Message
                     style={{
                        background: "#F9F1DA",
                        color: "#454545",
                        fontSize: "14px",
                        fontWeight: "600",
                     }}
                     duration={5000}
                  >
                     An invitation has been sent through email
                  </Message>,
                  "topCenter",
               )
               callback && callback()
            })
            .catch((err) => {
               toaster.push(
                  <Message
                     style={{
                        background: "#F9F1DA",
                        color: "#454545",
                        fontSize: "14px",
                        fontWeight: "600",
                     }}
                     duration={5000}
                  >
                     {`Failed to send an invitation. ${err.message}`}
                  </Message>,
                  "topCenter",
               )
               errCallback && errCallback(err)
            })
            .finally(() => {
               setIsProcessing(false)
            })
      } else {
         toast.error("Please select negotiation date!")
      }
   }

   // const handleSendInvitation = () => {
   //    const data = {
   //       _id: procurementVendorData._id,
   //       startDate: procurementVendorData.startDate,
   //    }

   //    dispatch(
   //       setNegotiationSchedulePerVendor(data, (res) => {
   //          fetchVendorScores()
   //          modalRef.current.hide()
   //          toaster.push(
   //             <Message
   //                style={{
   //                   background: "#F9F1DA",
   //                   color: "#454545",
   //                   fontSize: "14px",
   //                   fontWeight: "600",
   //                }}
   //                duration={5000}
   //             >
   //                Invitation has been sent trough email
   //             </Message>,
   //             "topCenter",
   //          )
   //       }),
   //    )
   // }

   const RenderTable = () => {
      /**
       * table handling
       * ----------------
       */

      // const tableColumns = ["Company Name", "Schedule", "Status", "Saving"]
      const tableColumns = ["Company Name", "Schedule", "Status"]

      const fnShowOffer = (negotiation, isRefresh = false) => {
         if (negotiation?.status !== Constant.STATUS.NOT_SCHEDULED) {
            setNegotiation(negotiation)
            if (negotiationBatch.negotiationType === Constant.NEGO_TYPE.OFFLINE) {
               if (showOffer) {
                  setShowOffer(false)
                  // modalNegoOfflineRef.current.hide()
               } else {
                  setShowOffer(true)
                  // modalNegoOfflineRef.current.show()
               }
            } else if (negotiationBatch.negotiationType === Constant.NEGO_TYPE.ONLINE) {
               history.push("/negotiation/session-online/buyer/" + negotiationBatch._id + "/" + negotiation._id)
            }
         }

         if (isRefresh) {
            handleGetNegotiationByBatchId(batchId)
         }
      }

      const fnShowOffer2 = (negotiation, isRefresh = false, closeModalOffer = false) => {
         // if (negotiation?.status !== Constant.STATUS.NOT_SCHEDULED) {
         if (negotiationBatch.negotiationType === Constant.NEGO_TYPE.OFFLINE) {
            if (!closeModalOffer) {
               negotiationOfferRef.current.show(negotiation)
            } else {
               negotiationOfferRef.current.hide()
            }
         } else if (
            negotiationBatch.negotiationType === Constant.NEGO_TYPE.ONLINE &&
            negotiationBatch.status === Constant.STATUS.ON_GOING &&
            (negotiation.status === Constant.STATUS.NOT_STARTED ||
               negotiation.status === Constant.STATUS.ON_GOING ||
               negotiation.status === Constant.STATUS.DONE ||
               negotiation.status === Constant.STATUS.CANCELED)
         ) {
            history.push("/negotiation/session-online/buyer/" + negotiationBatch._id + "/" + negotiation._id)
         } else {
            if (!closeModalOffer) {
               negotiationOfferRef.current.show(negotiation)
            } else {
               negotiationOfferRef.current.hide()
            }
         }

         if (isRefresh) {
            handleGetNegotiationByBatchId(batchId)
         }
         // }
      }

      /**
       * end
       * ----------------
       */

      return (
         <>
            <Row>
               <Col className="mb-5">
                  <GreyCard style={{ paddingBottom: "34px" }}>
                     <Row>
                        <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                           <p
                              style={{
                                 fontSize: "18px",
                                 fontWeight: "bold",
                                 color: "#454545",
                              }}
                           >
                              Negotiation Vendor
                           </p>
                        </Col>
                     </Row>

                     <hr style={{ background: "#C4C4C4" }} />

                     <MuiDataTable.CustomTable>
                        <TableHead>
                           <TableRow>
                              {tableColumns.map((columns, index) => (
                                 <TableCell key={index} className="py-2" sx={{ whiteSpace: "nowrap" }}>
                                    {columns}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {negotiationBatch &&
                              negotiations?.map((negotiation, index) => (
                                 <TableRow
                                    key={index}
                                    sx={{
                                       background: "#F3F5F4",
                                       "&:hover": {
                                          background: "transparent",
                                       },
                                    }}
                                 >
                                    <TableCell sx={{ minWidth: "200px" }} className="py-2">
                                       <a
                                          // href="#"
                                          style={{
                                             color: "#454545",
                                             fontWeight: "500",
                                             textDecoration: "underline",
                                             cursor: "pointer",

                                             "&:hover": {
                                                background: "transparent",
                                                cursor: "pointer",
                                             },
                                          }}
                                          // onClick={() => {
                                          //    negotiation?.status === Constant.STATUS.NOT_SCHEDULED &&
                                          //       modalNegotiationVendorRef.current.show(dataProcurementVendors[0]?._id)
                                          // }}
                                          onClick={(e) => {
                                             fnShowOffer2(negotiation)
                                          }}
                                       >
                                          {negotiation?.procurementVendor.name}
                                       </a>
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }} className="py-2">
                                       <div
                                          className="d-flex align-items-center"
                                          onClick={() => {
                                             setProcurementVendorData(negotiation)
                                          }}
                                       >
                                          {negotiation.startDate ? (
                                             <div>
                                                <Dateinfo date={negotiation.startDate} format="dd mmmm yyyy" />
                                                <span style={{ color: "#030303" }}>
                                                   {new Date(negotiation.startDate).toLocaleString("en-US", {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      hour12: false,
                                                   })}
                                                </span>
                                             </div>
                                          ) : negotiationBatch?.status === Constant.STATUS.ON_GOING ? (
                                             <Buttonaction
                                                // disabled={
                                                //    negotiation?.status !== Constant.STATUS.NOT_SCHEDULED ||
                                                //    currentNegoStatusAndData?.status === Constant.STATUS.DONE ||
                                                //    currentNegoStatusAndData?.status === Constant.STATUS.CANCELED
                                                // }
                                                small
                                                label="Set Schedule"
                                                blueOcean
                                                onClick={(e) => handleOpenModal(negotiation)}
                                             >
                                                <img src={CalendarWhiteIcon} className="ml-3" />
                                             </Buttonaction>
                                          ) : (
                                             <p>-</p>
                                          )}
                                       </div>
                                    </TableCell>
                                    <TableCell className="py-2">
                                       <div className="d-flex align-items-center">
                                          {negotiationBatch?.status === Constant.STATUS.CANCELED ? (
                                             negotiation?.status === Constant.STATUS.NOT_SCHEDULED ? (
                                                <p>-</p>
                                             ) : (
                                                <Statuslabel
                                                   className="mx-0"
                                                   label={negotiation.status}
                                                   status={negotiation.status}
                                                ></Statuslabel>
                                             )
                                          ) : (
                                             <Statuslabel
                                                className="mx-0"
                                                label={negotiation.status}
                                                status={negotiation.status}
                                             ></Statuslabel>
                                          )}
                                       </div>
                                    </TableCell>
                                    {/* <TableCell className="py-2">
                                       <div
                                          className="d-flex align-items-center"
                                          style={{
                                             color: negotiation.saving > 0 ? "#4DAF51" : "#F44437",
                                             fontWeight: "700",
                                          }}
                                       >
                                          {negotiation.saving.toFixed(2) + "%"}
                                       </div>
                                    </TableCell> */}
                                 </TableRow>
                              ))}
                        </TableBody>
                     </MuiDataTable.CustomTable>
                  </GreyCard>
               </Col>
            </Row>
            {/* {negotiation && dataProcurement && (
               <BuyerNegotiationOffline
                  procurement={dataProcurement}
                  showOffer={showOffer}
                  fnShowOffer={fnShowOffer}
                  negotiation={negotiation}
                  // modalNegoOfflineRef={modalNegoOfflineRef}
               ></BuyerNegotiationOffline>
            )} */}
            <NegotiationOffer
               passedInRef={negotiationOfferRef}
               fnShowOffer={fnShowOffer2}
               negotiationBatch={negotiationBatch}
            />
         </>
      )
   }

   const handleDisabledDate = (date) => {
      const startDate = currentNegoStatusAndData.startDate
      // if (startDate) {
      return DatePickerUtil.disabledDate(date, startDate, dataProcurement.endDate)
      // }
   }

   return (
      <>
         <RenderTable />

         {/* Schedule Modal */}
         <ScheduleModal
            passedInRef={modalRef}
            negotiation={selectNegotiation}
            procurementVendorData={procurementVendorData}
            setProcurementVendorData={setProcurementVendorData}
            // handleSendInvitation={handleSendInvitation}
            handleDisabledDate={handleDisabledDate}
            handleSubmitInvitation={handleSubmitInvitation}
            isProcessing={isProcessing}
            currentNegoStatusAndData={currentNegoStatusAndData}
         />

         {/* Set Unit Price Nego Modal */}
         <NegoUnitPriceModal
            passedInRef={negoUnitPriceRef}
            dataProcurement={dataProcurement}
            vendorItems={vendorItems}
            setVendorItems={setVendorItems}
            procurementVendorData={procurementVendorData}
            setNegotiationDataList={setNegotiations}
            fetchVendorScores={fetchVendorScores}
         />
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   vendorScores: state.negotiationReducer.vendorScores,
})

export default connect(mapStateToProps)(NegotiaionSetSchedule)
