import React, { useEffect, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import AuctionBannerTransparent from "../../../assets/images/AuctionBannerTransparent.svg"
import Buttonaction from "../../../components/Buttonaction"
import ErrorField from "../../../components/Errorfield"
import SuccessModal from "../../../components/Successmodal"
import { register } from "../../../redux/action/authAction"
import styles from "../../../styles/Registerpage.module.css"

function Registerpage({ isAppLoading }) {
   const dispatch = useDispatch()
   const history = useHistory()

   const [firstName, setFirstName] = useState("")
   const [lastName, setLastName] = useState("")
   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   const [confirmPassword, setConfirmPassword] = useState("")
   const [optIn, setOptIn] = useState(false)
   const modalRef = useRef()
   const [errors, setErrors] = useState([])

   const handleSubmit = async (e) => {
      e.preventDefault()

      if (!firstName || !email || !password || !confirmPassword || !optIn) {
         toast.error("Please complete the register form")
      } else {
         dispatch(
            register(
               {
                  firstName,
                  lastName,
                  email,
                  password,
                  confirmPassword,
                  optIn,
               },
               () => {
                  modalRef.current.show({
                     title: "Registration Success",
                     description: "We have sent you a link to verify your email account",
                  })
               },
               (errors) => {
                  setErrors(errors)
               },
            ),
         )
      }
   }

   const handleSetEmail = async (value) => {
      setEmail((prev) => value.toLowerCase())
   }

   return (
      <>
         <div
            className="col d-flex justify-content-center my-5 no-paddings"
            style={{
               border: "none",
               boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
               width: "75%",
               marginLeft: "12%",
            }}
         >
            <div className="container-fluid no-gutters no-paddings">
               <Row className="no-gutters">
                  <Col
                     lg={6}
                     className={`d-flex align-items-center justify-content-center ${styles.leftBanner}`}
                     style={{
                        borderRadius: 12,
                        background: "linear-gradient(180deg, rgba(7, 114, 182, 0.25) 0%, #0772B6 100%)",
                     }}
                  >
                     <Row>
                        <Col className="d-flex justify-content-center flex-column">
                           <img
                              style={{
                                 maxWidth: 166,
                                 margin: "0 auto",
                                 paddingBottom: 10,
                              }}
                              src={AuctionBannerTransparent}
                              className="float-center"
                           />
                           <h1
                              className="text-center"
                              style={{
                                 fontSize: "32px",
                                 fontWeight: "700",
                                 color: "#FFFFFF",
                              }}
                           >
                              Ruang Auction by Promise
                           </h1>
                        </Col>
                     </Row>
                  </Col>

                  <Col className="px-5 py-5" lg={6}>
                     <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 mt-5" controlId="formFirstName">
                           <Form.Label style={{ color: "#909090" }}>First Name</Form.Label>
                           <Form.Control
                              pattern="^[a-zA-Z ]+$"
                              type="text"
                              placeholder="Type your first name"
                              value={firstName}
                              onChange={(e) => {
                                 const pattern = /^[a-zA-Z ]+$/
                                 if (pattern.test(e.target.value) || !e.target.value) {
                                    setFirstName(e.target.value)
                                 }
                              }}
                           />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLastName">
                           <Form.Label style={{ color: "#909090" }}>Last Name</Form.Label>
                           <Form.Control
                              pattern="^[a-zA-Z ]+$"
                              type="text"
                              placeholder="Type your last name"
                              value={lastName}
                              onChange={(e) => {
                                 const pattern = /^[a-zA-Z ]+$/
                                 if (pattern.test(e.target.value) || !e.target.value) {
                                    setLastName(e.target.value)
                                 }
                              }}
                           />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                           <Form.Label style={{ color: "#909090" }}>Email</Form.Label>
                           <Form.Control
                              type="email"
                              placeholder="Type your email address"
                              value={email}
                              onChange={(e) => 
                                 handleSetEmail(e.target.value)
                              }
                           />
                           <ErrorField errors={errors} fieldName="email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPassword">
                           <Form.Label style={{ color: "#909090" }}>Password</Form.Label>
                           <Form.Control
                              type="password"
                              placeholder="Set your password"
                              onChange={(e) => setPassword(e.target.value)}
                           />
                           <ErrorField errors={errors} fieldName="password" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                           <Form.Label style={{ color: "#909090" }}>Confirm Password</Form.Label>
                           <Form.Control
                              type="password"
                              placeholder="Confirm your password"
                              onChange={(e) => setConfirmPassword(e.target.value)}
                           />
                           {password !== confirmPassword && (
                              <Form.Text className="text-danger">Password tidak sama</Form.Text>
                           )}
                        </Form.Group>

                        <Form.Group
                           className="mb-3"
                           controlId="formBasicCheckbox"
                           style={{ maxWidth: "450px", color: "#909090" }}
                        >
                           <Form.Check
                              className="optIn"
                              type="checkbox"
                              label="Saya setuju untuk dihubungi oleh pihak Ruang Auction untuk proses pengajuan uji coba gratis"
                              onChange={({ target }) => setOptIn(target.checked)}
                           />
                        </Form.Group>

                        <Form.Group className="mb-3 text-center" controlId="formButton">
                           <Buttonaction
                              type="submit"
                              disabled={
                                 !optIn ||
                                 !firstName ||
                                 !email ||
                                 !password ||
                                 !confirmPassword ||
                                 password !== confirmPassword
                              }
                              loading={isAppLoading}
                              blueOcean
                              label="Register"
                              style={{ width: "100%" }}
                           />
                        </Form.Group>

                        <Form.Group className="mt-3 text-center">
                           <p style={{ color: "#909090", fontSize: 14 }}>
                              Already have an account ?
                              <Link
                                 style={{
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    color: "#0772B6",
                                    textDecoration: "underline",
                                    marginLeft: "16px",
                                 }}
                                 to="/buyer-login"
                              >
                                 Log in
                              </Link>
                           </p>
                        </Form.Group>
                     </Form>
                  </Col>
               </Row>
            </div>
         </div>

         <SuccessModal passedInRef={modalRef}>
            <Row>
               <Col className="d-flex justify-content-center mt-3">
                  <Buttonaction
                     type="button"
                     blueOcean
                     label="Continue to login"
                     large
                     onClick={() => history.push("/buyer-login")}
                  />
               </Col>
            </Row>
         </SuccessModal>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(Registerpage)
