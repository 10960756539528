import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { Col, Row } from "react-bootstrap"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { orderBy as _orderBy } from "lodash"

import Dateinfo from "../../../components/Dateinfo"
import Statuslabel from "../../../components/Statuslabel"
import Buttonaction from "../../../components/Buttonaction"
import { GreyCard } from "../../../components/styled/Generalcard"
import CarbonWarning from "../../../assets/images/CarbonWarning.svg"
import CreateNegotiationSessionModal from "./modal/CreateSession"

import { fetchAllNegotiationBatch } from "../../../redux/action/negotiationAction"

// Mui Library
import MuiDataTable from "../../../components/MuiDataTable"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material"
import moment from "moment"
import toast from "react-hot-toast"
// ==============

const NegotiationSessionList = ({
   // negotiationBatches,
   passedInRef,
   handleExpand,
   procurementHasEnded,
}) => {
   const dispatch = useDispatch()
   const history = useHistory()
   const { procurementId } = useParams()
   const createNegotiationSessionModalRef = useRef()
   const [onGoingSession, setOnGoingSession] = useState(false)
   const [negotiationBatches, setNegotiationBatches] = useState([])

   // const handleNewNegotiationSession = () => {
   //    const _onGoingSession = negotiationBatches.some((batch) => batch.status === "ON_GOING")

   //    if (_onGoingSession) {
   //       setOnGoingSession(true)
   //    } else {
   //       createNegotiationSessionModalRef.current.show()
   //    }
   // }

   const handleNewNegotiationSession = () => {
      history.push("/negotiation/session/" + procurementId + "/new")
   }

   useEffect(() => {
      dispatch(
         fetchAllNegotiationBatch(procurementId, (res) => {
            setNegotiationBatches(res)
         }),
      )
   }, [])

   /**
    * table handling
    * ----------------
    */
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")
   const tableColumns = ["Session Number", "Start Time", "End Time", "Status"]

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Negotiation Number"
            ? "name"
            : column === "Start Time"
            ? "startDate"
            : column === "End Time"
            ? "endDate"
            : column === "Status" && "status"

      let result = []
      result = _orderBy(negotiationBatches, [forColumn], [_order])

      setNegotiationBatches(result)
   }

   /**
    * end
    * ----------------
    */

   return (
      <div ref={passedInRef} id="nego" onMouseEnter={() => handleExpand({ expand: "nego" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard style={{ paddingBottom: "34px" }}>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Negotiation Session
                        </p>
                        {procurementHasEnded ? (
                           <Tooltip
                              title="Can't create negotiation due to procurement has ended"
                              arrow
                              placement="left"
                              sx={{ whiteSpace: "nowrap", width: "50px" }}
                           >
                              <Buttonaction disabled blueOcean label="New Negotiation" faIcon={faPlusCircle} />
                           </Tooltip>
                        ) : (
                           <Buttonaction
                              blueOcean
                              label="New Negotiation"
                              faIcon={faPlusCircle}
                              onClick={handleNewNegotiationSession}
                           />
                        )}
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  {onGoingSession && (
                     <Info>
                        <img src={CarbonWarning} style={{ position: "absolute", top: "0", left: "0" }} />
                        <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-5">
                           Can’t create new session when last session status is still on going.
                        </p>
                     </Info>
                  )}

                  <MuiDataTable.CustomTable containerStyles={{ maxHeight: "300px" }} stickyHeader={true}>
                     <TableHead>
                        <TableRow>
                           {tableColumns.map((column, index) => (
                              <TableCell
                                 key={index}
                                 sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                                 className="py-2"
                              >
                                 <TableSortLabel
                                    active={orderBy === column}
                                    direction={order}
                                    onClick={() => handleRequestSort(column)}
                                    IconComponent={ExpandLessIcon}
                                 >
                                    {column}
                                 </TableSortLabel>
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {negotiationBatches.length > 0 &&
                           negotiationBatches.map((batch, index) => (
                              <TableRow
                                 key={index}
                                 sx={{
                                    background: "#F3F5F4",
                                    "&:hover": {
                                       background: "transparent",
                                    },
                                 }}
                              >
                                 <TableCell sx={{ maxWidth: "150px" }} className="py-2">
                                    <a
                                       // href="#"
                                       style={{
                                          color: "#454545",
                                          fontWeight: "600",
                                          textDecoration: "underline",
                                          cursor: "pointer",
                                       }}
                                       onClick={() => {
                                          history.push(`/negotiation/session/${procurementId}/${batch._id}`)
                                       }}
                                    >
                                       {batch.name + "/Negotiation"}
                                    </a>
                                 </TableCell>
                                 <TableCell className="py-2">
                                    {batch.startDate && moment(batch.startDate).format("DD/MM/yyyy HH.mm")}
                                    {!batch.startDate && <span>-</span>}
                                 </TableCell>
                                 <TableCell className="py-2">
                                    {batch.endDate && moment(batch.endDate).format("DD/MM/yyyy HH.mm")}
                                    {!batch.endDate && <span>-</span>}
                                 </TableCell>
                                 <TableCell className="py-2">
                                    <Statuslabel label={batch.status} status={batch.status} className="mx-0" />
                                 </TableCell>
                              </TableRow>
                           ))}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </GreyCard>
            </Col>
         </Row>

         <CreateNegotiationSessionModal passedInRef={createNegotiationSessionModalRef} />
      </div>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   // negotiationBatches: state.negotiationReducer.negotiationBatches,
})

export default connect(mapStateToProps)(NegotiationSessionList)

const Info = styled.div`
   background-color: #f9f1da;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
   margin-left: 10px;
   margin-right: 10px;
`
