import { dispatch } from "react-hot-toast"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function fetchAllAuctionInvitation({ auctionId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction/fetch-invitation-by-auction/${auctionId}`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         callback(resp.data.data)

         await dispatch({
            type: "FETCH_AUCTION_INVITATIONS",
            payload: resp.data.data,
         })
      } else {
         errorCallback()
      }
   }
}

export function getInitialPriceV2({ auctionId }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/auction/v2/${auctionId}`)
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getInitialPrice({ procurementId, includeAllSession = "" }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction-session/auction-vendor-offers/${procurementId}?includeAllSession=${includeAllSession}`,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getInitialPriceByUnit({ procurementId, itemId, includeAllSession = "" }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction-session/auction-vendor-offer-items/${procurementId}/${itemId}?includeAllSession=${includeAllSession}`,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getAuctionItems({ auctionId, page = 1, limit = 10, query = "" }, callback, errCallback) {
   return async (dispatch) => {
      dispatch({
         type: "GET_AUCTION_ITEMS_PENDING",
      })

      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction-item/${auctionId}/find-by-auction?query=${query}&page=${page}&limit=${limit}`,
      )

      if (resp.status == 200) {
         dispatch({
            type: "GET_AUCTION_ITEMS_FULFILLED",
            payload: resp.data.data,
         })
         callback(resp.data.data)
      } else {
         dispatch({
            type: "GET_AUCTION_ITEMS_REJECTED",
         })
         errCallback()
      }
   }
}

export function getVendorItems({ vendorId }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/${vendorId}/by-vendor`,
      )
      if (resp.status == 200) {
         callback(resp.data.data)
      }
   }
}

export function saveAuctionSessionAsDraft(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction/save-as-draft`,
         payload,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getAuctionStatus(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/get-status`, payload)

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }

         await dispatch({
            type: "GET_AUCTION_STATUS",
            payload: resp.data.data,
         })
      }
   }
}

export function resetAuctionStatus() {
   return async (dispatch) => {
      await dispatch({
         type: "RESET_AUCTION_STATUS",
      })
   }
}

export function getVendorAuctionInvitations({ accountId, procurementId }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction/fetch-vendor-auction-data-invitations/${accountId}/${procurementId}`,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getCurrentTimeServer(callback) {
   return async () => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction/current-time`)

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function createAuctionVendor({ auction, vendorIds }, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/auction-vendor`, {
         auction,
         vendorIds,
      })

      if (resp.status === 200) {
         if (callback) {
            callback(resp)
         }
      }
   }
}

export function getAuctionVendors({auctionId}, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/auction-vendor/find-by-auction/${auctionId}`)
      
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errCallback()
      }
   }
}

export function getAuctionInvitationData({auctionInvitationId}, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/auction-invitation/${auctionInvitationId}`)
      
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         if(errCallback) {
            errCallback(resp.data)
         }
      }
   }
}
