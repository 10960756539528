import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { SelectPicker } from "rsuite"
import LogoNormal from "../../../assets/images/AuctionBanner.png"
import Buttonaction from "../../../components/Buttonaction"
import ButtonExpand from "../../../components/Buttonexpand"
import Dropzone from "../../../components/Dropzone"
import ErrorField from "../../../components/Errorfield"
import { getMyProfile } from "../../../redux/action/accountAction"
import { refreshToken } from "../../../redux/action/authAction"
import {
   fetchCities,
   fetchCompanyTypes,
   fetchDistrict,
   fetchPhonePrefixes,
   fetchProvinces,
} from "../../../redux/action/masterDataAction"
import { saveCompanyAddress, saveCompanyInfo, saveCompanyLegal } from "../../../redux/action/onboardingAction"
import Formservice from "../../../services/Formservice"
import SuccessModal from "../../../components/Successmodal"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import Stepper from "../../../components/Stepper"
import TrashIcon from "../../../assets/images/TrashSimple.svg"
import DocumentIcon from "../../../assets/images/Document.svg"
import { updateCompany } from "../../../redux/action/companyAction"
import toast from "react-hot-toast"

function Onboarding({
   isAppLoading,
   dataCompanyTypes,
   dataPhonePrefixes,
   dataProvinces,
   dataCities,
   dataDistricts,
   dataProfile,
}) {
   const history = useHistory()
   const dispatch = useDispatch()
   const stepGeneralInfoRef = useRef()
   const stepCompanyAddressRef = useRef()
   const stepLegalInfoRef = useRef()
   const [expandGeneral, setExpandGeneral] = useState(false)
   const [expandAddress, setExpandAddress] = useState(false)
   const [expandLegal, setExpandLegal] = useState(false)
   const [finishedForm, setFinishedForm] = useState({
      generalInfo: false,
      companyAddress: false,
      legalInfo: false,
   })
   const [dataCompany, setDataCompany] = useState(null)

   const modalRef = useRef()

   const [errors, setErrors] = useState([])
   const [generalInfoFields, setGeneralInfoFields] = useState({
      companyCode: "",
      companyName: "",
      companyPicName: "",
      companyEmail: "",
      companyPhone: "",
      companyPhonePrefix: "",
      companyType: "",
   })

   const [addressFields, setAddressFields] = useState({
      addressDetail: "",
      zipCode: "",
      districtName: "",
      districtCode: "",
      cityName: "",
      cityCode: "",
      provinceName: "",
      provinceCode: "",
      countryName: "",
   })

   const [legalFields, setLegalFields] = useState({
      npwp: "",
      idNumber: "",
      npwpAttachments: [],
      idNumberAttachments: [],
   })

   const acceptedFileExt = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/jpg",
      "image/png",
   ]

   const accordionTitleStyle = {
      fontSize: "16px",
      color: "#454545",
      fontFamily: "Roboto",
      fontWeight: "bolder",
   }

   const handleExpand = ({ expand }) => {
      expand === "general"
         ? (setExpandGeneral(!expandGeneral), setExpandAddress(false), setExpandLegal(false))
         : expand === "address"
         ? (setExpandGeneral(false), setExpandAddress(!expandAddress), setExpandLegal(false))
         : expand === "legal"
         ? (setExpandGeneral(false), setExpandAddress(false), setExpandLegal(!expandLegal))
         : ""
   }

   const acceptedNpwpItems = legalFields.npwpAttachments.map((file, index) => (
      <>
         <Card style={{ width: "100%" }} className="mt-2">
            <Card.Body>
               <Row className="d-flex align-items-center">
                  <Col lg={5} className="d-flex align-items-center" style={{ color: "#0772B6", fontSize: "12px" }}>
                     <img src={DocumentIcon} alt="document icon" style={{ marginRight: "8px" }} />
                     {file.path}
                  </Col>
                  <Col lg={4} className="text-muted" style={{ fontSize: "12px" }}>
                     {Math.floor(file.size * 0.001)} Kb
                  </Col>
                  <Col lg={3}>
                     <a
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeNpwp(file)}
                     >
                        <img src={TrashIcon} alt="trash icon" style={{ marginRight: "10px" }} />
                        Remove
                     </a>
                  </Col>
               </Row>
            </Card.Body>
         </Card>
      </>
   ))

   const removeNpwp = (file) => {
      const newFiles = [...legalFields.npwpAttachments]
      newFiles.splice(newFiles.indexOf(file), 1)
      // setNpwpFiles(newFiles)
      setLegalFields((state) => ({
         ...state,
         npwpAttachments: newFiles,
      }))
   }

   const acceptedSiupFiles = legalFields.idNumberAttachments.map((file, index) => (
      <>
         <Card style={{ width: "100%" }} className="mt-2">
            <Card.Body>
               <Row className="d-flex align-items-center">
                  <Col lg={5} className="d-flex align-items-center" style={{ color: "#0772B6", fontSize: "12px" }}>
                     <img src={DocumentIcon} alt="document icon" style={{ marginRight: "8px" }} />
                     {file.path}
                  </Col>
                  <Col lg={4} className="text-muted" style={{ fontSize: "12px" }}>
                     {Math.floor(file.size * 0.001)} Kb
                  </Col>
                  <Col lg={3}>
                     <a
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeSiup(file)}
                     >
                        <img src={TrashIcon} alt="trash icon" style={{ marginRight: "10px" }} />
                        Remove
                     </a>
                  </Col>
               </Row>
            </Card.Body>
         </Card>
      </>
   ))

   const removeSiup = (file) => {
      const newFiles = [...legalFields.idNumberAttachments]
      newFiles.splice(newFiles.indexOf(file), 1)
      setLegalFields((state) => ({
         ...state,
         idNumberAttachments: newFiles,
      }))
   }

   const changeProvince = (v) => {
      dispatch(fetchCities({ provinceId: v }))

      setAddressFields((state) => ({
         ...state,
         provinceCode: v,
         provinceName: dataProvinces.filter((x) => x.value === v)[0]?.label,
         cityCode: undefined,
         cityName: undefined,
      }))
   }

   const changeCity = (v) => {
      const city = dataCities.find((city) => city.value === v)
      dispatch(fetchDistrict({ cityId: city.cityId }))

      setAddressFields((state) => ({
         ...state,
         // cityCode: city.code,
         cityCode: v,
         cityName: dataCities.filter((x) => x.value === v)[0]?.label,
         zipCode: dataCities.filter((x) => x.value === v)[0]?.postalCode,
         districtCode: undefined,
         districtName: undefined,
      }))
   }

   const changeDistrict = (v) => {
      setAddressFields((state) => ({
         ...state,
         districtCode: v,
         districtName: dataDistricts.filter((x) => x.value === v)[0]?.label,
      }))
   }

   const submitGeneralInfo = () => {
      dispatch(
         saveCompanyInfo(
            generalInfoFields,
            (res) => {
               setFinishedForm({ ...finishedForm, generalInfo: true })
               setExpandGeneral(!expandGeneral)
               setDataCompany(res)

               if (!finishedForm.companyAddress) {
                  setExpandAddress(true)
               } else {
                  setExpandLegal(true)
               }

               // renew token
               dispatch(refreshToken())
            },
            (errors) => {
               setErrors(errors)
            },
         ),
      )
   }

   const submitAddress = () => {
      if (dataProfile.company || dataCompany) {
         dispatch(
            saveCompanyAddress(
               addressFields,
               () => {
                  setFinishedForm({ ...finishedForm, companyAddress: true })
                  setExpandAddress(!expandAddress)

                  if (!finishedForm.generalInfo) {
                     setExpandGeneral(true)
                  } else {
                     setExpandLegal(true)
                  }
               },
               (errors) => {
                  setErrors(errors)
               },
            ),
         )
      } else {
         toast.error("Please complete General Info first")
      }
   }

   const submitLegal = () => {
      if (dataProfile.company || dataCompany) {
         dispatch(
            saveCompanyLegal(
               legalFields,
               () => {
                  setFinishedForm({ ...finishedForm, legalInfo: true })
                  setExpandLegal(!expandLegal)

                  if (!finishedForm.generalInfo) {
                     setExpandGeneral(true)
                  } else {
                     setExpandAddress(true)
                  }
               },
               (errors) => {
                  setErrors(errors)
               },
            ),
         )
      } else {
         toast.error("Please complete General Info first")
      }
   }

   useEffect(() => {
      // general section
      dispatch(fetchCompanyTypes())
      dispatch(fetchPhonePrefixes())

      // address section
      dispatch(fetchProvinces())

      // get user profile
      dispatch(
         getMyProfile(({ account, company }) => {
            if (Object.entries(company).length > 0 && company.address && company.legalInfo) {
               // redirect user to dashboard when they have onboarding already
               location.href = "/dashboard"
            }

            if (company && company.legalInfo) {
               setFinishedForm((prev) => ({ ...prev, generalInfo: true, legalInfo: true }))
            }

            // if (company && !company.legalInfo) {
            //    setFinishedForm({ ...finishedForm, generalInfo: true })
            // }

            if (account) {
               setGeneralInfoFields((v) => ({
                  ...v,
                  companyEmail: account.email,
               }))
            }

            if (company) {
               setDataCompany(company)
               setFinishedForm((prev) => ({ ...prev, generalInfo: true }))
               setGeneralInfoFields(company)

               const _address = company.address
               if (_address) {
                  setAddressFields(_address)

                  dispatch(fetchCities({ provinceId: _address.provinceCode }))
                  dispatch(fetchDistrict({ cityId: _address.cityCode }))
               }

               const _legal = company.legalInfo
               if (_legal) {
                  setLegalFields((state) => ({
                     ...state,
                     npwp: _legal.npwp,
                     idNumber: _legal.idNumber,
                  }))
               }
            }

            if (company.address) {
               setFinishedForm((prev) => ({ ...prev, companyAddress: true }))
            }

            if (company.legalInfo) {
               setFinishedForm((prev) => ({ ...prev, legalInfo: true }))
            }
         }),
      )
   }, [])

   useEffect(() => {
      const { generalInfo, companyAddress, legalInfo } = finishedForm
      if (generalInfo && companyAddress && legalInfo && !dataProfile?.company?.isOnboarding) {
         dispatch(
            updateCompany({ ...dataCompany, isOnboarding: true }, () => {
               modalRef.current.show({
                  title: "Onboarding Success",
                  description:
                     "Congratulation, you've just finished your onboarding process. Click the button below to start your journey with Promise Auction",
               })
            }),
         )
      }
   }, [finishedForm])

   return (
      <>
         <Col lg={12} className="mt-4">
            <img src={LogoNormal} alt="" style={{ maxWidth: "100px", height: "auto", marginLeft: "15px" }} />
         </Col>

         <div
            style={{
               position: "fixed",
               right: 0,
               zIndex: 1000,
               paddingTop: "50px",
            }}
         >
            <div className="d-flex flex-column">
               <Stepper
                  passedInRef={stepGeneralInfoRef}
                  active={{ expandGeneral }}
                  generalInfo={finishedForm.generalInfo}
                  href="#general"
                  onClick={() => handleExpand({ expand: "general" })}
               >
                  General Info
               </Stepper>
               <Stepper
                  passedInRef={stepCompanyAddressRef}
                  active={{ expandAddress }}
                  companyAddress={finishedForm.companyAddress}
                  href="#address"
                  onClick={() => handleExpand({ expand: "address" })}
               >
                  Address Info
               </Stepper>
               <Stepper
                  passedInRef={stepLegalInfoRef}
                  active={{ expandLegal }}
                  legalInfo={finishedForm.legalInfo}
                  href="#legal"
                  onClick={() => handleExpand({ expand: "legal" })}
               >
                  Legal Info
               </Stepper>
            </div>
         </div>

         <InputGroup className="col d-flex justify-content-center mt-5 mb-5">
            <Card
               id="general"
               className="float-center"
               style={{
                  width: "540px",
                  boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
               }}
            >
               <Card.Body>
                  <Row>
                     <Col className="d-flex align-items-center" lg={10} style={accordionTitleStyle}>
                        General Info
                     </Col>

                     <Col className="d-flex align-items-center justify-content-end" lg={2}>
                        <ButtonExpand
                           id="general_info"
                           isExpand={expandGeneral}
                           onClick={() => handleExpand({ expand: "general" })}
                        />
                     </Col>
                  </Row>

                  <hr className={`${!expandGeneral ? "mb-0" : ""}`} style={{ background: "#C4C4C4" }} />

                  {expandGeneral && (
                     <Row className="mt-3">
                        <Col className="px-4" lg={12}>
                           <Form>
                              <Row>
                                 <Col>
                                    <Form.Group className="mb-3" controlId="company_type">
                                       <Form.Label className="text-muted">Company Type</Form.Label>
                                       <SelectPicker
                                          placeholder="Choose type"
                                          value={generalInfoFields.companyType}
                                          onChange={(v) =>
                                             setGeneralInfoFields((_s) => ({
                                                ..._s,
                                                companyType: v,
                                             }))
                                          }
                                          className="selectpicker"
                                          data={dataCompanyTypes ? dataCompanyTypes : []}
                                          searchable={false}
                                       />
                                       <ErrorField errors={errors} fieldName="companyType" />
                                    </Form.Group>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <Form.Group className="mb-3" controlId="company_name">
                                       <Form.Label className="text-muted">Company Name</Form.Label>
                                       <InputField
                                          type="text"
                                          placeholder="Company name"
                                          size="sm"
                                          onChange={({ target }) =>
                                             setGeneralInfoFields((v) => ({
                                                ...v,
                                                companyName: target.value,
                                                companyCode: Formservice.TransformNameToCode(target.value),
                                             }))
                                          }
                                          value={generalInfoFields.companyName}
                                       />

                                       <ErrorField errors={errors} fieldName="companyName" />
                                    </Form.Group>
                                 </Col>

                                 <Col>
                                    <Form.Group
                                       className="mb-3"
                                       controlId="formBasicEmail"
                                       style={{ fontSize: "14px" }}
                                    >
                                       <Form.Label className="text-muted">Code</Form.Label>
                                       <InputField
                                          type="text"
                                          placeholder="Auto text"
                                          size="sm"
                                          onChange={({ target }) =>
                                             setGeneralInfoFields((v) => ({
                                                ...v,
                                                companyCode: target.value,
                                             }))
                                          }
                                          value={generalInfoFields.companyCode}
                                          style={{ pointerEvents: "none" }}
                                       />
                                       <ErrorField errors={errors} fieldName="companyCode" />
                                    </Form.Group>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <Form.Group className="mb-3" controlId="pic_name" style={{ fontSize: "14px" }}>
                                       <Form.Label className="text-muted">PIC Name</Form.Label>
                                       <InputField
                                          type="text"
                                          placeholder="PIC name"
                                          size="sm"
                                          value={generalInfoFields.companyPicName}
                                          onChange={({ target }) =>
                                             setGeneralInfoFields((v) => ({
                                                ...v,
                                                companyPicName: target.value,
                                             }))
                                          }
                                       />
                                       <ErrorField errors={errors} fieldName="companyPicName" />
                                    </Form.Group>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <Form.Group className="mb-3" controlId="email" style={{ fontSize: "14px" }}>
                                       <Form.Label className="text-muted">Email</Form.Label>
                                       <InputField
                                          type="email"
                                          placeholder="Email address"
                                          size="sm"
                                          value={generalInfoFields.companyEmail}
                                          onChange={({ target }) =>
                                             setGeneralInfoFields((v) => ({
                                                ...v,
                                                companyEmail: target.value,
                                             }))
                                          }
                                       />
                                       <ErrorField errors={errors} fieldName="companyEmail" />
                                    </Form.Group>
                                 </Col>
                              </Row>

                              <Row>
                                 <Col>
                                    <Form.Label className="text-muted">Phone Number</Form.Label>
                                 </Col>
                              </Row>

                              <Row className="px-1">
                                 <Col xs={2} className="p-0">
                                    <Form.Group className="mb-5" controlId="phone_number">
                                       <SelectPicker
                                          value={generalInfoFields.companyPhonePrefix}
                                          onChange={(v) => {
                                             setGeneralInfoFields((_v) => ({
                                                ..._v,
                                                companyPhonePrefix: v,
                                             }))
                                          }}
                                          className="selectpicker"
                                          data={dataPhonePrefixes}
                                          searchable={false}
                                       />
                                    </Form.Group>
                                 </Col>
                                 <Col className="pr-0 mr-0">
                                    <Form.Group className="mb-5" style={{ fontSize: "14px" }}>
                                       <InputField
                                          type="text"
                                          placeholder="Phone number without 0"
                                          size="sm"
                                          onChange={({ target }) => {
                                             const pattern = /^[0-9]+$/
                                             if (pattern.test(target.value) || !target.value) {
                                                setGeneralInfoFields((v) => ({
                                                   ...v,
                                                   companyPhone: target.value,
                                                }))
                                             }
                                          }}
                                          value={generalInfoFields.companyPhone}
                                       />
                                       <ErrorField errors={errors} fieldName="companyPhone" />
                                    </Form.Group>
                                 </Col>
                              </Row>

                              <Buttonaction
                                 blueOcean
                                 loading={isAppLoading}
                                 type="button"
                                 label="Continue"
                                 style={{ width: "104.3%", margin: "0 -10px -80px" }}
                                 onClick={submitGeneralInfo}
                              />
                           </Form>
                        </Col>
                     </Row>
                  )}
               </Card.Body>
            </Card>
         </InputGroup>

         <InputGroup className={`col d-flex justify-content-center ${expandGeneral && "mt-5"} mb-5`}>
            <Card
               id="address"
               className="d-flex justify-content-center"
               style={{
                  width: "540px",
                  boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
               }}
            >
               <Card.Body>
                  <Row>
                     <Col className="d-flex align-items-center" lg={10} style={accordionTitleStyle}>
                        Company Address
                     </Col>

                     <Col className="d-flex align-items-center justify-content-end" lg={2}>
                        <ButtonExpand isExpand={expandAddress} onClick={() => handleExpand({ expand: "address" })} />
                     </Col>
                  </Row>

                  <hr className={`${!expandAddress ? "mb-0" : ""}`} style={{ background: "#C4C4C4" }} />

                  {expandAddress && (
                     <Row className="mt-3">
                        <Col lg={12}>
                           <Form>
                              <Form.Group className="mb-3" controlId="address_detail" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">Address Detail</Form.Label>
                                 <InputField
                                    type="text"
                                    placeholder="Address detail"
                                    size="lg"
                                    value={addressFields.addressDetail}
                                    onChange={(v) =>
                                       setAddressFields((s) => ({
                                          ...s,
                                          addressDetail: v.target.value,
                                       }))
                                    }
                                 />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="country" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">Country</Form.Label>
                                 <InputField
                                    type="text"
                                    placeholder="Country name"
                                    size="sm"
                                    value={addressFields.countryName}
                                    onChange={(v) =>
                                       setAddressFields((s) => ({
                                          ...s,
                                          countryName: v.target.value,
                                       }))
                                    }
                                 />
                                 <ErrorField errors={errors} fieldName="countryName" />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="province" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">Province</Form.Label>
                                 <SelectPicker
                                    placeholder="Select province"
                                    className="selectpicker"
                                    value={addressFields.provinceCode}
                                    data={dataProvinces}
                                    onChange={changeProvince}
                                 />
                                 <ErrorField errors={errors} fieldName="provinceCode" />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="city" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">City</Form.Label>
                                 <SelectPicker
                                    placeholder="Select city"
                                    className="selectpicker"
                                    value={addressFields.cityCode}
                                    data={dataCities}
                                    onChange={changeCity}
                                 />
                                 <ErrorField errors={errors} fieldName="companyCode" />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="district" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">District</Form.Label>
                                 <SelectPicker
                                    placeholder="Select district"
                                    className="selectpicker"
                                    value={addressFields.districtCode}
                                    data={dataDistricts}
                                    onChange={changeDistrict}
                                 />
                                 <ErrorField errors={errors} fieldName="districtCode" />
                              </Form.Group>

                              <Form.Group className="mb-5" controlId="zip_code" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">Zip Code</Form.Label>
                                 <InputField
                                    type="text"
                                    placeholder="Zip Code"
                                    size="sm"
                                    value={addressFields.zipCode}
                                    onChange={(v) =>
                                       setAddressFields((s) => ({
                                          ...s,
                                          zipCode: v.target.value,
                                       }))
                                    }
                                 />
                              </Form.Group>

                              <Buttonaction
                                 disabled={!dataCompany}
                                 blueOcean
                                 loading={isAppLoading}
                                 type="button"
                                 label="Continue"
                                 style={{ width: "100%", marginBottom: "-80px" }}
                                 onClick={submitAddress}
                              />
                           </Form>
                        </Col>
                     </Row>
                  )}
               </Card.Body>
            </Card>
         </InputGroup>

         <InputGroup className={`col d-flex justify-content-center ${expandAddress && "mt-5"} mb-5`}>
            <Card
               id="legal"
               className="d-flex justify-content-center"
               style={{
                  width: "540px",
                  boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
                  marginBottom: "50px",
               }}
            >
               <Card.Body>
                  <Row>
                     <Col lg={10} style={accordionTitleStyle}>
                        Legal Info
                     </Col>

                     <Col className="d-flex align-items-center justify-content-end" lg={2}>
                        <ButtonExpand isExpand={expandLegal} onClick={() => handleExpand({ expand: "legal" })} />
                     </Col>
                  </Row>

                  <hr className={`${!expandLegal ? "mb-0" : ""}`} style={{ background: "#C4C4C4" }} />

                  {expandLegal && (
                     <Row className="mt-3">
                        <Col lg={12}>
                           <Form>
                              <Form.Group className="mb-3" controlId="npwp" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">NPWP</Form.Label>
                                 <InputField
                                    value={legalFields.npwp}
                                    type="text"
                                    placeholder="Type the number"
                                    size="sm"
                                    onChange={({ target }) =>
                                       setLegalFields((_v) => ({
                                          ..._v,
                                          npwp: target.value,
                                       }))
                                    }
                                 />
                                 <ErrorField errors={errors} fieldName="npwp" />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="formNpwp">
                                 <Dropzone
                                    maxFiles={1}
                                    acceptedFiles={
                                       "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpg, image/jpeg"
                                    }
                                    onDrop={(acceptedFiles) => {
                                       //  const file = acceptedFiles.dataTransfer.files[0]
                                       //  if (acceptedFileExt.includes(file.type)) {
                                       //     handleImageToBase64(file, index)
                                       //  } else {
                                       //     toast.error("Only jpeg, jpg, and png extension are allowed")
                                       //  }
                                       acceptedFiles.length === 1
                                          ? setLegalFields((v) => ({
                                               ...v,
                                               npwpAttachments: acceptedFiles.map((file) =>
                                                  Object.assign(file, {
                                                     preview: URL.createObjectURL(file),
                                                  }),
                                               ),
                                            }))
                                          : toast.error("Accept only 1 file")
                                    }}
                                 >
                                    {acceptedNpwpItems}
                                 </Dropzone>
                                 <p
                                    style={{
                                       fontSize: "12px",
                                       fontWeight: "500",
                                       marginTop: "8px",
                                    }}
                                 >
                                    Accept Maximum 1 file with format .pdf .doc .docx .jpg .jpeg
                                 </p>
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="id_number" style={{ fontSize: "14px" }}>
                                 <Form.Label className="text-muted">ID Number</Form.Label>
                                 <InputField
                                    value={legalFields.idNumber}
                                    type="text"
                                    placeholder="Type ID number"
                                    size="sm"
                                    onChange={({ target }) =>
                                       setLegalFields((_v) => ({
                                          ..._v,
                                          idNumber: target.value,
                                       }))
                                    }
                                 />
                                 <ErrorField errors={errors} fieldName="idNumber" />
                              </Form.Group>

                              <Form.Group className="mb-5" controlId="formBasicEmail">
                                 <Dropzone
                                    maxFiles={1}
                                    acceptedFiles={
                                       "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpg, image/jpeg"
                                    }
                                    onDrop={(acceptedFiles) => {
                                       acceptedFiles.length === 1
                                          ? setLegalFields((v) => ({
                                               ...v,
                                               idNumberAttachments: acceptedFiles.map((file) =>
                                                  Object.assign(file, {
                                                     preview: URL.createObjectURL(file),
                                                  }),
                                               ),
                                            }))
                                          : toast.error("Accept only 1 file")
                                    }}
                                 >
                                    {acceptedSiupFiles}
                                 </Dropzone>
                                 <p
                                    style={{
                                       fontSize: "12px",
                                       fontWeight: "500",
                                       marginTop: "8px",
                                    }}
                                 >
                                    Accept Maximum 1 file with format .pdf .doc .docx .jpg .jpeg
                                 </p>
                              </Form.Group>

                              <Buttonaction
                                 disabled={!dataCompany}
                                 blueOcean
                                 loading={isAppLoading}
                                 type="button"
                                 label="Save"
                                 style={{ width: "100%", marginBottom: "-80px" }}
                                 onClick={submitLegal}
                              />
                           </Form>
                        </Col>
                     </Row>
                  )}
               </Card.Body>
            </Card>
         </InputGroup>

         <SuccessModal passedInRef={modalRef}>
            <Row>
               <Col className="d-flex justify-content-center mt-3">
                  <Buttonaction
                     type="button"
                     blueOcean
                     label="Go to dashboard"
                     large
                     onClick={() => (location.href = "/dashboard")}
                  />
               </Col>
            </Row>
         </SuccessModal>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataCompanyTypes: state.masterDataReducer.dataCompanyTypes,
      dataPhonePrefixes: state.masterDataReducer.dataPhonePrefixes,
      dataProvinces: state.masterDataReducer.dataProvinces,
      dataCities: state.masterDataReducer.dataCities,
      dataDistricts: state.masterDataReducer.dataDistricts,
      dataProfile: state.accountReducer.dataProfile,
   }
}

export default connect(mapStateToProps)(Onboarding)

const InputField = styled(Form.Control)`
   height: 44px;
`

const InputGroup = styled.div`
   filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
`
