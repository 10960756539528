import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Form, FormControl, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Buttonaction from "../../../components/Buttonaction"
import ErrorField from "../../../components/Errorfield"
import ModalForm from "../../../components/Modalform"
import { getProcurement, updateProcurementVendorTechnicalScore } from "../../../redux/action/procurementAction"

function ScoringTechnicalForm({ isAppLoading, passedInRef, dataProcurement }) {
   const dispatch = useDispatch()
   const [formState, setFormState] = useState({
      vendorName: "",
      procurementNumber: "",
      procurementVendorId: "",
      score: 0,
   })

   const modalRef = useRef()
   const { procurementId } = useParams()
   const [errors, setErrors] = useState([])
   const [technicalScore, setTechnicalScore] = useState(0)

   const saveScore = () => {
      dispatch(
         updateProcurementVendorTechnicalScore(
            {
               procurementId,
               procurementVendorId: formState.procurementVendorId,
               score: technicalScore,
            },
            () => {
               modalRef.current.hide()
               toast.success("Vendor technical score has been updated")
            },
            (errors) => {
               setErrors(errors)
               console.log(errors)
            },
         ),
      )
   }

   useImperativeHandle(passedInRef, () => ({
      show: ({ vendorName, procurementNumber, procurementVendorId }) => {
         setFormState((v) => ({
            ...v,
            vendorName: vendorName,
            procurementNumber: procurementNumber,
            procurementVendorId: procurementVendorId,
         }))

         modalRef.current.show()
      },
   }))

   return (
      <ModalForm passedInRef={modalRef} title="Technical Score">
         <Row className="m-2">
            <Col className="px-4" lg={12}>
               <Form className="roboto-form">
                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Procurement Number</p>
                     </Form.Label>
                     <Col>
                        <span>{formState.procurementNumber}</span>
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Vendor</p>
                     </Form.Label>
                     <Col>
                        <span>{formState.vendorName}</span>
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Score</p>
                     </Form.Label>
                     <Col className="no-paddings mr-3" sm={2} style={{ fontSize: "12px", marginTop: "5px" }}>
                        <FormControl
                           type="number"
                           value={formState.score}
                           onChange={({ target }) => {
                              if (target.value <= 100) {
                                 setFormState((v) => ({
                                    ...v,
                                    score: target.value,
                                 }))
                                 setTechnicalScore(
                                    target.value * (dataProcurement?.evaluationScoring.technicalValue / 100),
                                 )
                              }
                           }}
                        />
                        <ErrorField errors={errors} fieldName="score" />
                     </Col>
                     <Col style={{ fontSize: "12px", marginTop: "5px" }}>
                        <span>Max score of 100.00</span>
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Technical Percentage</p>
                     </Form.Label>
                     <Col>
                        {dataProcurement?.evaluationScoring && (
                           <span>{dataProcurement?.evaluationScoring.technicalValue.toFixed(2)} %</span>
                        )}
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Technical Score</p>
                     </Form.Label>
                     <Col>
                        <span>{technicalScore.toFixed(2)}</span>
                     </Col>
                  </Form.Group>
               </Form>
            </Col>
         </Row>

         <div className="mt-4 mb-5 mx-4 d-flex justify-content-end">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => modalRef.current.hide()}
               />
            </div>
            <Buttonaction
               type="button"
               blueOcean
               label="Save"
               loading={isAppLoading}
               onClick={() => saveScore()}
               large
            />
         </div>
      </ModalForm>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataProcurement: state.procurementReducer.dataProcurement,
   }
}

export default connect(mapStateToProps)(ScoringTechnicalForm)
